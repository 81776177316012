import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCalendar } from "react-icons/bs";
import "../body/bodypage.css";
import { IoLocationOutline } from "react-icons/io5";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import persian_en from "react-date-object/locales/persian_en"
import transition from "react-element-popper/animations/transition"
import opacity from "react-element-popper/animations/opacity";
import axios from "axios";
import Details from "./public/details";
import "react-multi-date-picker/styles/layouts/mobile.css"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../slices/UserInformation";
import { setTravel } from "../slices/Travelinfo";
import Cookies from "js-cookie";
import { StateApp } from "../context/Context";
import Loading from "../Loading/Loading";
import { message, notification } from "antd";
import Modal from "react-modal";
import LoginRegister from "../Login/LoginRegister";
import SuccessLogin from "../Modal/Success Login";
import { url } from "../url";
import { IoReload } from "react-icons/io5";
import { GiCarWheel } from "react-icons/gi";

const Body = () => {
    const [counter, setCounter] = useState(1);
    const [searchVal, setSearchVal] = useState("");
    const [searchDestination, setSearchDestination] = useState("");
    const [inputVal, setInputVal] = useState("");
    const [inputValDestination, setInputValDestination] = useState("");
    const [openLocation, setOpenLocation] = useState(false);
    const [openDestination, setopenDestination] = useState(false);
    const [cityName, setCityName] = useState([]);
    const [value, setValue] = useState(new Date());
    const [showCartype, setShowCartype] = useState(false);
    const [selectCartype, setSelectCartype] = useState("");
    const navigate = useNavigate(null);
    const [locationId, setlocationId] = useState()
    const [destanationId, setDestanationId] = useState()
    const { userInfo, error } = useSelector((state) => state.userinformation);
    const { accessToken } = userInfo;
    const [openTimePicker, setOpenTimePicker] = useState(false);
    const [selectedHour, setSelectedHour] = useState(null);
    const [selectedMinute, setSelectedMinute] = useState(null);
    const [time, setTime] = useState("انتخاب ساعت");
    const [carTypeMenu, setCarTypeMenu] = useState(false);
    const travelInfo = useSelector((state) => state.travelinformation.travelInfo);
    const [allCarTypes, setAllCarTypes] = useState([]);
    const { travelId, origin, destination } = travelInfo;
    const dispatch = useDispatch();
    const { loading, setLoading, openLogin, showSuccessLogin, updateUserName_header, setUpdateUserName_header } = useContext(StateApp);
    const [categoryLoading, setCategoryLoading] = useState(true);
    const [cityLoading, setCityLoading] = useState(true);

    if (localStorage.getItem("antdUpdate") === "true") {
        message.success("اطلاعات با موفقیت ذخیره شد!")
    }

    useEffect(() => {
        setLoading(false)
        axios.get(`${url}/states/v1/get-city-list`, {
            params: {
                withoutPagination: true,
            }
        })
            .then((res) => {
                const data = res.data.data;
                console.log(res.data);
                setCityName(data);
            })
            .catch((error) => {
                if (error.status !== 200)
                    setCityLoading(false);
                else
                    setCityLoading(true);
            });
    }, []);

    const openCarTypeMenu = () => {
        setCarTypeMenu(!carTypeMenu);
        axios.get(`${url}/car-category/v1/get-car-category-list`).then(res => {
            console.log("ey daaad :", res.data.data);
            setAllCarTypes(res.data.data);
        }).catch(err => {
            if (err.response.status !== 200)
                setCategoryLoading(false)
            else {
                setCategoryLoading(true)
            }
        }
        )
    }

    const handleSubmit = () => {
        const requestData = {
            origin_city: locationId,
            destination_city: destanationId,
            travel_date: value,
            travel_hour_time: time,
            car_type_id: selectCartype._id,
            passenger_count: counter
        };

        setLoading(true)
        axios.post(`${url}/travel/v1/create-new-travel`, requestData, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => {
                navigate("/passenger");
                window.scrollTo(0, 0);
                Cookies.set('TravelId', response.data.data._id, { expires: 1 / 24 });
                dispatch(setTravel({
                    travelId: Cookies.get("TravelId"),
                    origin: locationId,
                    destination: destanationId,
                }));
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setIsModalOpen(true)
                    if (error.response) {
                        if (error.response.status === 401) {
                            notification.error({
                                message: "ابتدا وارد حساب کاربری شوید",
                                description: "",
                                placement: 'topRight',
                            });
                        }
                        else {
                            notification.error({
                                message: error.response.data.message,
                                description: "",
                                placement: 'topRight',
                            });
                        }
                    }
                    setLoading(false);
                } else {
                    console.error("Error:", error);
                    notification.error({
                        message: error.response.data.message,
                        description: "",
                        placement: 'topRight',
                    });
                    setLoading(false);
                }
            });
    };
    const incrementCounter = () => {
        if (counter !== 3)
            setCounter(counter + 1);
    };
    const decrementCounter = () => {
        if (counter > 1) {
            setCounter(counter - 1);
        }
    };


    const handleOpenlocation = () => {
        setOpenLocation(!openLocation);
        setopenDestination(false);
        setShowCartype(false);
    };

    const handleOpenDestination = () => {
        setopenDestination(!openDestination);
        setOpenLocation(false);
        setShowCartype(false);
    };

    const HandleShowtype = () => {
        setShowCartype(!showCartype);
        setopenDestination(false);
        setOpenLocation(false);
    };

    const handleDateChange = (date) => {
        const gregorianDate = date.convert(persian, persian_en).format("YYYY-MM-DD");
        setValue(gregorianDate)
    };

    const handleTimePicker = () => {
        setOpenTimePicker(!openTimePicker);
    }

    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const minutes = [0, 15, 30, 45];


    const handleHourClick = (hour) => {
        setSelectedHour(hour);
    };

    const handleMinuteClick = (minute) => {
        setSelectedMinute(minute);
    };

    const handleTime = () => {
        if (selectedHour !== null && selectedMinute !== null) {
            setTime(`${selectedHour.toString().padStart(2, '0')}:${selectedMinute.toString().padStart(2, '0')}`);
            setOpenTimePicker(false);
        }
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding: '0px 0px 0px 0px',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            borderRadius: '10px',
            outline: 'none',
            border: 'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCancel}
                style={customStyles}
                className="modalMobile"
            >
                <button className="custom-modal-close" onClick={handleCancel}>
                    &times;
                </button>
                {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
            </Modal>


            <div className=" md:w-full tablet10:w-full flex justify-center mt-[60px]">
                <div
                    className=" tablet10:shadow-none md:h-full md:w-full tablet10:w-full tablet10:h-full tablet10:bg-transparent md:bg-transparent md:shadow-none shadow-md bg-opacity-40 flex w-[90%] h-[110px] bg-transparent rounded-[20px] justify-center items-center bg-white ">
                    <div
                        id="tripManagement"
                        className=" w-full md:p-0 p-4 md:flex-col md:w-full md:justify-center md:items-center tablet10:flex-col tablet10:justify-center tablet10:items-center tablet10:w-full flex justify-center items-center gap-4 ">
                        <div className="input-container">
                            <div className="w-full font-medium h-full overscroll-none">
                                <div
                                    onClick={handleOpenlocation}
                                    className="cursor-pointer gap-2 h-full bg-transparent flex w-full p-2 items-center justify-between rounded">
                                    <div
                                        type="text"
                                        className="flex items-center bg-transparent w-full outline-none h-full placeholder:text-gray-500"
                                    >
                                        {inputVal ? inputVal : 'انتخاب مبدا'}
                                    </div>
                                    <div>
                                        <BiChevronDown
                                            className={`transform transition-transform duration-300 ${openLocation ? 'rotate-180' : 'rotate-0'}`}
                                            size={24} />
                                    </div>
                                </div>
                                <div
                                    className="rounded-xl bg-white relative w-full z-10 mt-[4px] shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
                                    <div
                                        className={` max-h-[400px] ${openLocation ? "block" : "hidden"}`}>
                                        <div className="w-full justify-between flex items-center pl-3 pt-2">
                                            <input
                                                value={searchVal}
                                                onChange={(e) => setSearchVal(e.target.value)}
                                                type="text"
                                                placeholder="جستجو"
                                                className="placeholder:text-gray-500 w-full p-2 outline-none"
                                            />
                                            <AiOutlineSearch size={18} className="text-gray-500" />
                                        </div>
                                        <ul className="overflow-y-auto max-h-[260px]">
                                            {cityName?.map((cityItem, index) => (
                                                <li
                                                    onClick={() => {
                                                        if (cityItem?.name !== inputVal) {
                                                            setInputVal(cityItem?.name);
                                                            setlocationId(cityItem._id)
                                                        }
                                                        setOpenLocation(!openLocation);
                                                    }}
                                                    key={index}
                                                    className={`pt-2 pb-2 cursor-pointer p-2 hover:bg-blue-500 text-black hover:text-white ${cityItem?.name.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase()) ? "block" : "hidden"}`}
                                                >
                                                    <p className="flex gap-2 items-center">
                                                        <IoLocationOutline size={20} />
                                                        {cityItem.name}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="w-full font-medium h-full overscroll-none">
                                <div
                                    onClick={handleOpenDestination}
                                    className={`cursor-pointer p-2 h-full bg-transparent flex w-full items-center justify-between rounded`}>
                                    <div
                                        className="flex items-center placeholder:text-gray-500 bg-transparent w-full outline-none h-full"
                                    >
                                        {inputValDestination ? inputValDestination : 'انتخاب مقصد'}
                                    </div>
                                    <div>
                                        <BiChevronDown
                                            className={`${openLocation ? 'md:hidden' : ''} transform transition-transform duration-300 ${openDestination ? 'rotate-180' : 'rotate-0'}`}
                                            size={24} />
                                    </div>
                                </div>
                                <div
                                    className=" rounded-xl bg-white w-full relative z-10 mt-[4px] shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)]">
                                    <div
                                        className={`max-h-[400px] ${openDestination ? "block" : "hidden"}`}>
                                        <div
                                            className="w-full justify-between flex items-center pl-3 pt-2 overscroll-none">
                                            <input
                                                value={searchDestination}
                                                onChange={(e) => setSearchDestination(e.target.value)}
                                                type="text"
                                                placeholder="جستجو"
                                                className="placeholder:text-gray-500 w-full p-2 outline-none"
                                            />
                                            <AiOutlineSearch size={18} className="text-gray-500" />
                                        </div>
                                        <ul className="overflow-y-auto max-h-[260px]">
                                            {cityName?.map((cityItem, index) => (
                                                <li
                                                    onClick={() => {
                                                        if (cityItem?.name !== inputValDestination) {
                                                            setInputValDestination(cityItem?.name);
                                                            setDestanationId(cityItem._id)
                                                        }
                                                        setopenDestination(!openDestination);
                                                    }}
                                                    key={index}
                                                    className={`pt-2 pb-2 cursor-pointer p-2 hover:bg-blue-500 text-black hover:text-white ${cityItem?.name.toLocaleLowerCase().includes(searchDestination.toLocaleLowerCase()) ? "block" : "hidden"}`}
                                                >
                                                    <p className="flex gap-2 items-center">
                                                        <IoLocationOutline size={20} />
                                                        {cityItem.name}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:!w-[90%] mx-5 gap-4 flex w-auto ">
                            <div className="md:col-span-1 !w-full ">
                                <div className="input-container !w-[200px] md:!w-[100%]">
                                    {/*<BsCalendar className="input-Icon"/>*/}
                                    <DatePicker
                                        animations={[
                                            opacity(),
                                            transition({
                                                from: 40,
                                                transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
                                            }),
                                        ]}
                                        calendar={persian}
                                        locale={persian_fa}
                                        calendarPosition="bottom-right"
                                        placeholder="تاریخ سفر"
                                        className=" md:rmdp-mobile cursor-pointer"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            border: "none",
                                            outline: "none",
                                            backgroundColor: "transparent",
                                        }}
                                        // format="YYYY-MM-DD"
                                        format="DD MMMM"
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="md:flex md:flex-col cursor-pointer">
                                    <div onClick={handleTimePicker}
                                        className={`input-container-forSelectCar md:!w-[100%] tablet10:!200px    `}>
                                        <div
                                            className=" ml-2 mr-2 items-center w-full h-full flex text-black justify-between">
                                            <div>
                                                <p>{time}</p>
                                            </div>
                                            <div>
                                                <div>
                                                    <BiChevronDown
                                                        className={` ${openLocation || openDestination ? 'md:hidden' : ''} transform transition-transform duration-300 ${openTimePicker ? 'rotate-180' : 'rotate-0'}`}
                                                        size={24} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative">
                                    {openTimePicker && (
                                        <div
                                            className="shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] mt-1  gap-4 p-2 rounded-[10px] bg-white h-auto absolute md:w-full w-full">
                                            <div className="flex gap-4 w-full justify-between pl-4 pr-4">
                                                <div className="w-full flex flex-col items-center">
                                                    <p className="m-1">دقیقه</p>
                                                    <ul className="list-none p-0 m-0 w-full">
                                                        {minutes.map((minute) => (
                                                            <li
                                                                key={minute}
                                                                className={`hover:text-black hover:bg-gray-200 hover:rounded-lg cursor-pointer p-1 items-center flex flex-col w-full ${selectedMinute === minute ? 'bg-[#4F48EC] text-white rounded-lg' : 'bg-white text-black'}`}
                                                                onClick={() => handleMinuteClick(minute)}
                                                            >
                                                                {minute.toString().padStart(2, '0')}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="w-full flex flex-col items-center">
                                                    <p className="m-1">ساعت</p>
                                                    <ul className="list-none p-0 m-0 max-h-40 w-full overflow-y-scroll">
                                                        {hours.map((hour) => (
                                                            <li
                                                                key={hour}
                                                                className={` hover:bg-gray-200 hover:rounded-lg hover:text-black cursor-pointer items-center flex flex-col gap-2 p-1 ${selectedHour === hour ? 'bg-[#4F48EC] text-white rounded-lg' : 'bg-white text-black'}`}
                                                                onClick={() => handleHourClick(hour)}
                                                            >
                                                                {hour.toString().padStart(2, '0')}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="flex justify-center">
                                                <button onClick={handleTime}
                                                    className="bg-[#4F48EC] text-white p-2 rounded mt-4 w-[90%]">
                                                    ثبت
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:w-[90%] flex gap-4">
                            <div className="col-span-1" onClick={() => openCarTypeMenu()} >
                                <div className="md:flex md:flex-col cursor-pointer">
                                    <div onClick={HandleShowtype}
                                        className={`input-container-forSelectCar md:!w-[100%] tablet10:!200px ${showCartype ? '!border-black' : ''}`}>
                                        <div
                                            className=" ml-2 mr-2 items-center w-full h-full flex text-black justify-between">
                                            <div><p>
                                                {selectCartype.name === undefined ? "نوع خودرو" : selectCartype.name}
                                            </p></div>
                                            <div>
                                                <div>
                                                    <BiChevronDown
                                                        className={` ${openLocation || openDestination ? 'md:hidden' : ''} transform transition-transform duration-300 ${showCartype ? 'rotate-180' : 'rotate-0'}`}
                                                        size={24} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`relative`}>

                                        {
                                            carTypeMenu && (
                                                <div className="shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_10px_10px_30px_4px_rgba(45,78,255,0.15)] mt-1 flex flex-col gap-4 px-2 py-4 rounded-[10px] bg-white h-auto absolute md:w-full w-full">
                                                    {
                                                        categoryLoading ? (
                                                            <>
                                                                {
                                                                    allCarTypes?.map((item, index) => (
                                                                        <p key={index} onClick={() => setSelectCartype(item)}>{item.name}</p>
                                                                    ))
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className="w-full flex justify-center text-[20px] animate-spin">
                                                                <IoReload />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div
                                    className=" gap-2 justify-center input-container-counter md:!w-full flex items-center tablet10:!200px">
                                    <button
                                        onClick={incrementCounter}
                                        className=" w-[24px] h-[24px] bg-[#4F48EC] text-white text-16 font-semibold rounded-[5px] flex items-center justify-center"
                                    >
                                        +
                                    </button>
                                    <p className="text-center flex gap-2">
                                        <span className="">{counter}</span>
                                        مسافر
                                    </p>
                                    <button
                                        onClick={decrementCounter}
                                        className=" w-[24px] h-[24px] bg-[#4F48EC] text-white text-16 font-semibold rounded-[5px] flex items-center justify-center"
                                    >
                                        -
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button onClick={handleSubmit}
                            className=" items-center flex justify-center md:w-[90%] md:h-[50px] bg-[#4F48EC] w-[200px] h-[48px] rounded-[10px] tablet10:w-[60%]">
                            {loading ? (
                                <Loading />
                            ) : (
                                <p style={{ color: "white", fontSize: "16px", fontWeight: "500" }}>ثبت درخواست</p>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <Details />
        </>
    );
};
export default Body;
