import React, { useContext, useEffect, useState } from "react";
import { StateApp } from "../context/Context";
import UpdateProfile from "../Login/UpdateProfile";
import Modal from "react-modal";
import SuccessImage from "../Modal/public/Success.svg"
import { useNavigate } from "react-router-dom";


const SuccessLogin = () => {
    const [updateProfile, setUpdateProfile] = useState(false)
    const { completedProfile, } = useContext(StateApp)
    const handleUpdateProfile = () => {
        setUpdateProfile(true)
    }
    const downloadAppModalStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            padding: "60px",
            transform: "translate(-50%, -50%)",
            position: "fixed",
            borderRadius: "10px",
            outline: "none",
            backgroundColor: "white",
            border: "none",
            zIndex: "9999",
            width: "50%"
        },
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            height: "100vh",
            backdropFilter: "blur(5px)",
        },
    };

    return (
        <>
            {updateProfile ? (<div>
                <UpdateProfile />
            </div>) : (

                <div>
                    <div className=" rounded-[20px] bg-white w-[600px] h-[400px] md:w-full md:h-full p-4" style={{ fontFamily: "IRANSans" }}>
                        <div className="flex flex-col justify-center items-center">
                            <img className="mt-[32px]" src={SuccessImage} />
                            <div className="flex flex-col justify-center items-center">
                                <p className="text-[24px] mt-[18px] md:text-[20px]">
                                    تبریک میگم ورود شما با
                                    <span className="block text-center"> موفقیت انجام شد.</span>
                                </p>
                                <div className="cursor-pointer mb-4" onClick={handleUpdateProfile}>
                                    {!completedProfile ? (
                                        <div>
                                            <p className="text-[16px] text-[#8A8D97] mt-[32px] md:text-[14px]">
                                                پروفایل شما کامل
                                                <span className="text-red-700"> نیست </span>
                                                برای تکمیل پروفایل خود کلیک کنید.
                                            </p>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className="text-[16px] text-[#8A8D97] mt-[32px] md:text-[14px]">
                                                پروفایل شما کامل است.
                                                برای <span className="text-darkBlue" onClick={() => (<UpdateProfile />)}>ویرایش</span> پروفایل خود کلیک کنید.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default SuccessLogin
