import React, { useEffect, useRef, useState } from 'react'
import Header2 from '../header/Header2'
import Footer from '../footer/Footer'
import axios from 'axios'
import { url } from '../url'
import { FaCross, FaTicketAlt } from 'react-icons/fa'
import 'moment-jalaali';
import { message, notification } from "antd";
import Modal from "react-modal";
import { useSelector } from 'react-redux'
//icons
import { TiDelete } from "react-icons/ti";
import { FaUser } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import { FaEarListen } from 'react-icons/fa6'

const UserTickets = () => {
  const [openTicket, setOpenTicket] = useState(false)
  const mom = require("moment-jalaali");
  const [userTickets, setUserTickets] = useState([]);
  const [saveId, setSaveId] = useState("");
  const [ticketResponse, setTicketResponse] = useState([])
  const { userInfo, error } = useSelector((state) => state.userinformation);
  const [subject, setSubject] = useState("");
  const [newPassengerResponse, setNewPassengerResponse] = useState("");
  const [specificTicketId, setSpecificTicketId] = useState("");
  const [updateMessages, setUpadateMessages] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(null)

  useEffect(() => {
    axios.get(`${url}/tickets/v1/get-my-ticket-list-passenger`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(res => {
      setUserTickets(res.data.data.docs)
    }).catch(err => {
    })
  }, [])

  const finalList = [...userTickets].reverse();
  console.log(specificTicketId);

  const getTicketMessages = () => {
    axios.get(`${url}/tickets/v1/get-ticket-messages-passenger/${specificTicketId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then(res => {
      setOpenTicket(true)
      setTicketResponse(res.data.data)
    }).catch(err => {
      notification.error({
        message: "خطا ! لطفا بعدا امتحان کنید !",
        description: "",
        placement: 'topRight',
      });
    })
  }
  const messagesBox = useRef(null);

  useEffect(() => {
    // بررسی اینکه messagesBox حتماً تعریف شده باشد
    if (messagesBox.current) {
      messagesBox.current.scrollTop = messagesBox.current.scrollHeight;
    }
  }, []); // این effect زمانی اجرا می‌شود که ticketResponse تغییر کند

  const goToTop = () => {
    if (messagesBox.current) {
      messagesBox.current.scrollTop = messagesBox.current.scrollHeight;
    }
  };
  useEffect(() => {
    if (specificTicketId !== "")
      getTicketMessages()
  }, [updateMessages, specificTicketId])
  // console.log('passenger response:', ticketResponse?.sender?.passenger);
  // console.log('admin response:', ticketResponse?.sender?._id);
  // console.log('id response:', ticketResponse?._id);

  const sendNewResponse = (ticketId) => {
    axios.put(`${url}/tickets/v1/passenger-answer-ticket/${ticketId}`, {
      description: newPassengerResponse
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then(res => {
      setUpadateMessages(pre => !pre)
      setNewPassengerResponse("")
    }).catch(err => {
      notification.error({
        message: "خطا ! لطفا دوباره امتحان کنید...",
        description: "",
        placement: 'topRight',
      });
    })
  }

  const ticketStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      backgroundColor: "white",
      height: "700px",
      width: "80%",
      maxWidth: "1400px",
      outline: 'none',
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  return (
    <div className='w-screen h-screen flex flex-col justify-between'>
      <div className='w-full md:hidden'>
        <Header2 />
      </div>

      <div className=' flex flex-col items-center justify-center'>
        <div className=''>
          <h2 className='text-[25px] font-bold my-10'>تیکت های من</h2>
        </div>

        <div id='ticketHolder' className='flex flex-col rounded-lg w-[80%] lg:w-[96%] max-w-[1300px] gap-y-4 overflow-auto h-[600px] mb-10 p-10'>
          {
            finalList.map((item, index) => (
              <div dir='ltr' key={index} className='grid grid-cols-4 lg:grid-cols-2 lg:bg-slate-100 p-10 px-5 rounded-bl-[50px] lg:rounded-none border-b-2 border-slate-600
                rounded-tl-[50px] bg-white hover:drop-shadow-lg duration-[0.4s] cursor-pointer hover:text-[18px] hover:py-12 hover:px-7'>

                <div className='flex items-center gap-x-5 lg:col-span-2'>
                  <div className='flex items-center gap-x-3'>
                    <div className='bg-slate-600 text-white rounded-full w-[40px] h-[40px] flex justify-center items-center text-[24px]'><FaTicketAlt /></div>
                  </div>
                  <p title='موضوع تیکت' className='text-darkBlue text-nowrap'>{item.subject}</p>
                </div>

                <div className='flex items-center justify-center lg:justify-start lg:text-slate-600 lg:mt-2'>
                  <p>{mom(item.createdAt.slice(0, 10), 'YYYY/MM/DD').locale('fa').format('jYYYY/jM/jD')}</p>
                </div>

                <div className='flex items-center justify-center lg:justify-end '>
                  <p>وضعیت  :‌ {item?.status === "open" ? <span className='text-green-600'>باز</span> : <span className='text-red-600'>بسته</span>}</p>
                </div>

                <div className='flex items-center justify-center lg:justify-end lg:col-span-2'>
                  <p className='text-darkBlue' onClick={() => {
                    setSpecificTicketId(item?._id);
                    setSubject(item?.subject)
                    setTicketStatus(item?.status)
                  }}>پیام ها</p>
                </div>

              </div>
            ))
          }
        </div>
      </div>


      {/* -------- modal -------- */}

      <Modal
        isOpen={openTicket}
        onRequestClose={openTicket}
        style={ticketStyle}
        className="modalMobile w-[700px] md:!w-full md:!h-full md:!rounded-none"
      >

        <div id='ticketAnswerPage' className='flex flex-col p-2 h-full justify-between'>
          <div id='ticketAnswerPage' className='md:h-full'>
            <button className="custom-modal-close" onClick={() => {
              setOpenTicket(false)
              setSpecificTicketId("")
            }}>
              &times;
            </button>

            {/* messages container  */}

            <div ref={messagesBox} id='ticketAnswerPage' className='overflow-y-auto h-[600px] md:h-full md:relative'>
              <div className='w-full px-10 my-20 overflow-y-auto'>
                {
                  ticketResponse.map((item) => (
                    <>
                      {
                        item.sender.passenger ? (
                          <div className='mt-5'>
                            <div className='flex items-center gap-x-2 text-[24px]'>
                              <FaUser className='text-[24px]' />
                              <p className='text-black'>شما</p>
                            </div>

                            <div className='h-full bg p-5 my-2 border-r-[1px] md:m-0 border-amber-500'>
                              <div className='flex flex-col justify-start'>
                                <p className=' break-words'><span className='text-cyan-800'></span>{item.description}</p>
                                <p className='text-gray-600 mt-2'>{mom(item.createdAt, "YYYY/MM/DD").locale("fa").format('jYYYY/jM/jD')}</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='mt-5'>
                            <div className='flex items-center justify-end gap-x-2 text-[24px]'>
                              <p className='text-black'>پشتیبانی</p>
                              <MdSupportAgent className='text-[30px]' />
                            </div>

                            <div className='flex justify-end'>
                              <div className='h-full bg p-5 my-5 md:m-0 flex justify-end  border-l-[1px] border-amber-500 w-full'>
                                <div className='flex flex-col justify-end w-fit '>

                                  {/* ------- تاریخ پیام پشتیبانی -------- */}
                                  <p className='text-right   w-full max-w-[400px] text-wrap'>{item.description}</p>
                                  <p className='text-gray-600 mt-2'>{mom(item.createdAt, "YYYY/MM/DD").locale("fa").format('jYYYY/jM/jD')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </>
                  ))
                }
              </div>
            </div>
          </div>

          <div className='w-full flex items-center justify-center pb-6 md:absolute md:bottom-0'>
            {/* input & status */}
            {
              ticketStatus === "open" ? (
                <div className='w-[80%] bg-slate-400 rounded-full flex items-center px-3'>
                  <IoSend onClick={() => {
                    newPassengerResponse !== "" && sendNewResponse(specificTicketId);
                    goToTop()
                  }} className='flex-grow text-[25px] text-slate-800 cursor-pointer' />
                  <input value={newPassengerResponse} onChange={(e) => setNewPassengerResponse(e.target.value)} type='text' placeholder='پیام خود را بنویسید...'
                    className='bg-transparent md:border-none outline-none w-full placeholder:text-slate-700 h-[45px] pr-5'
                  />
                </div>
              ) :
                (
                  <div>
                    <p className='text-red-500 text-[20px]'>تیکت شما بسته شد !</p>
                  </div>
                )
            }
          </div>
        </div>

      </Modal>
      {/* <div className='w-full'>
        < Footer />
      </div> */}
    </div>
  )
}

export default UserTickets
