import {createSlice} from '@reduxjs/toolkit'

const initialState = {boolean: false}

function open(state) {
    state.boolean(true)
}

function close(state) {
    state.boolean(false)
}


const LoginModal = createSlice({
    name: 'login',
    initialState,
    reducers: {
        open,
        close
    }
})
export const {open: openModal, close: closeModal} = LoginModal.actions
export default LoginModal.reducer;