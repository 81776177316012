import React, { useContext, useEffect, useRef, useState } from 'react'
import DriverProfile from './public/driverProfile.svg';
import Message from './public/messageIcon.svg'
import Phone from './public/phoneIcon.svg'
import ArrowIcon from './public/arrowIcon.svg'
import showOptions from './public/showOptionsIcon.svg'
import ChatPage from './ChatPage';
import { StateApp } from '../context/Context';
import StopOnWay from './StopOnWay';
import axios from 'axios';
import errorIcon from '../PassengerTrip/public/errorIcon.svg'
import nmp_mapboxgl from '@neshan-maps-platform/mapbox-gl';
import { MapComponent, MapTypes } from "@neshan-maps-platform/mapbox-gl-react";
import "@neshan-maps-platform/mapbox-gl-react/dist/style.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Header2 from '../header/Header2';
import LoginRegister from '../Login/LoginRegister';
import SuccessLogin from '../Modal/Success Login';
import { url } from '../url';
import carMapic from './public/car-service-svgrepo-com.svg'
import Modal from "react-modal";
import { TbMapPinFilled } from "react-icons/tb";
import { GiCarWheel } from "react-icons/gi";

const Trip = () => {
  const [showOption, setShowOption] = useState(false);
  const [openMessagePage, setOpenMessagePage] = useState(false);
  const { driverInfo, setDriverInfo } = useContext(StateApp);
  const {
    carInfo, setCarInfo, loading,
    setLoading, openLogin, showSuccessLogin,
    updateUserName_header, setUpdateUserName_header,
    goToOptionPage, setGoToOptionPage
  } = useContext(StateApp)
  const [travelPrice, setTravelPrice] = useState("");
  const [noActiveTravel, setNoActiveTravel] = useState(false);
  const [reloadApi, setReloadApi] = useState(false)
  const [reloadAnimation, setReloadAnimation] = useState(false);
  const [unavailableToken, setUnavailableToken] = useState(false)
  const [apiError, setApiError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation()

  // const {latitude} = useContext(StateApp);
  // const {longitude} = useContext(StateApp)

  let navigate = useNavigate(null);

  const cancelTheTravel = () => {
    localStorage.removeItem("latitude");
    localStorage.removeItem("longitude");
    navigate("/");
  }
  const tryAgain = () => {
    setTimeout(() => {
      setReloadAnimation(false)
      navigate("/passengersTrips")
    }, 3000);
    setReloadAnimation(true)
  }

  const reload = () => {
    setApiError(false)
    setTimeout(() => {
      setReloadAnimation(false)
      setReloadApi(prev => !prev)
    }, 3000);
    setReloadAnimation(true)
  }

  // modal functions 
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      outline: 'none',
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  useEffect(() => {
    // ممکنه باگ براش پیش بیاد  (از طرف قناد): 
    try {
      const neshanMap = new nmp_mapboxgl.Map({
        mapType: nmp_mapboxgl?.Map?.mapTypes?.neshanVector,
        container: "map",
        zoom: 8,
        pitch: 0,
        center: [localStorage.getItem("longitude"), localStorage.getItem("latitude")],
        minZoom: 2,
        maxZoom: 21,
        trackResize: true,
        mapKey: "web.289f3d07d19d45fa9d579d1369a01b2c",
        poi: true,
        traffic: false,
        isZoomControlsEnabled: true,
        setZoomGesturesEnabled: true,
        mapTypeControllerOptions: {
          show: false,
        },
      });

      neshanMap.addControl(
        new nmp_mapboxgl.NavigationControl(),
        "top-right"
      );

      new nmp_mapboxgl.Marker()
        .setLngLat([localStorage.getItem("longitude"), localStorage.getItem("latitude")])
        .addTo(neshanMap);

    } catch {
      console.log("neshan map api key is not correct")
    }

    axios.get(`${url}/travel/v1/get-active-travel-passenger`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then((res) => {
      setDriverInfo(res.data.data.driver_snapshot)
      setCarInfo(res.data.data.car_snapshot)
      setApiError(false)
      setNoActiveTravel(false)
      setUnavailableToken(false)
      console.log(res.data);
      setTravelPrice(res.data.data.price)
    })
      .catch(error => {
        console.log(error);
        // token , network error , no travels
        if (error?.response?.status === 401)
          setUnavailableToken(true)
        if (error?.message == "Network Error")
          setApiError(true)
        if (error.response?.status === 404)
          setNoActiveTravel(true)
      })
  }, [apiError, unavailableToken, noActiveTravel, showSuccessLogin]);


  return (
    <div className='h-screen flex flex-col justify-between relative'>

      <div className='z-20 md:hidden'>
        <div>
          <Header2 />
        </div>
      </div>

      {
        apiError ? (
          <div className='flex items-center justify-center h-full'>
            <div className='flex items-center gap-5 w-full flex-col'>
              <div className='errorSign bg-red-600 flex items-center justify-center'>
                <div className='errorSign2 bg-red-100 text-red-600 text-[84px] flex items-center justify-center'>!</div>
              </div>

              <p className='text-red-600 text-center'>خطا در برقراری ارتباط! <br className='md:block hidden' /> لطفا اینترنت خود را بررسی کنید...</p>
              <div className={` ${reloadAnimation ? "flex items-center gap-x-2" : "invisible"}`}>
                <p>منتظر بمانید...</p>
                <GiCarWheel className={`${reloadAnimation && "animate-spin text-[25px]"}`} />
              </div>

              <div className=' flex md:items-stretch gap-x-2 md:flex-col'>
                <button className='bg-darkBlue text-[18px] border-2 rounded-lg py-2 px-4 text-white mt-5' onClick={reload}>تلاش مجدد</button>
                <button className='md:flex-col border-2 rounded-lg border-darkBlue text-darkBlue py-2 px-4 mt-5 md:mt-2' onClick={() => navigate("/")}>بازگشت به صفحه اصلی</button>
              </div>
            </div>
          </div>
        ) : (
          <>

            {/* unavailable token  */}
            {
              unavailableToken ? (
                <div className={`w-full h-full flex flex-col items-center text-[30px] flex`}>
                  <div className='flex flex-col gap-x-5 items-center mt-[50px]'>
                    <div className='text-[18px] text-center'>
                      <p className='mb-5 text-[24px] font-bold'>
                        مارانکو سفری مطمئن !
                      </p>
                      <p className='mb-5 px-10'>
                        با مارانکو به هرجایی که خواستید به راحتی سفر کنید
                      </p>
                    </div>
                    <img src={carMapic} />

                    <div className='flex flex-col mt-10'>
                      <div className='flex gap-x-5 items-center justify-center h-full '>
                        <p className='text-[24px] font-bold'>
                          شما وارد حساب کاربری نشده اید !
                        </p>
                      </div>

                      <div className='flex gap-x-2 md:flex-col w-full'>
                        <button onClick={() => setIsModalOpen(true)} className='py-2 md:w-[40%] text-[18px] mt-5 rounded-lg border-darkBlue text-darkBlue border-2 
                        hover:bg-darkBlue hover:text-white hover:duration-[0.4s] mx-auto w-full'>ثبت نام</button>

                        <button onClick={() => navigate("/passengersTrips")} className='py-2 md:w-[40%] text-[18px] mt-5 md:mt-2 rounded-lg border-red-500 text-red-500 border-2 
                        hover:bg-red-500 hover:text-white hover:duration-[0.4s] mx-auto w-full'>برگشت</button>
                      </div>

                    </div>
                  </div>
                </div>

              ) : (

                <>
                  {
                    noActiveTravel ? (
                      <div className={`w-full h-full flex flex-col items-center text-[30px] flex`}>
                        <div className='flex flex-col gap-x-5 items-center mt-[50px]'>
                          <div className='text-[18px] text-center'>
                            <p className='mb-5 text-[24px] font-bold'>
                              مارانکو سفری مطمئن !
                            </p>
                            <p className='mb-5 px-10'>
                              با مارانکو به هرجایی که خواستید به راحتی سفر کنید
                            </p>
                          </div>
                          <img src={carMapic} />

                          <div className='flex flex-col mt-10'>
                            <div className='flex gap-x-5 items-center justify-center h-full '>
                              <TbMapPinFilled className="animate-bounce text-[45px] " />
                              <p className='text-[24px] font-bold'>
                                در انتظار تایید راننده
                              </p>
                            </div>
                            <button onClick={() => navigate("/passengersTrips")} className='py-2 md:w-[40%] text-[18px] mt-5 rounded-lg border-red-500 text-red-500 border-2 
                            hover:bg-red-500 hover:text-white hover:duration-[0.4s] mx-auto w-full'>برگشت</button>
                          </div>
                        </div>
                      </div>

                    ) : (

                      <div className={` relative h-screen justify-center w-full mx-auto md:w-full md:border-none flex`}>
                        <div onClick={() => navigate(-1)} id='backPage' dir='ltr' className='flex items-center absolute cursor-pointer top-2 z-10 left-2'>
                          <div className=' bg-white w-[40px] h-[40px] rounded-md flex items-center
                           justify-center border-slate-700 border-2'>
                            <img className='rotate-[270deg]' src={ArrowIcon} />
                          </div>

                          <div id='backPage-strip' className='bg-white h-[40px] rounded-tr-md rounded-br-md ml-[-5px] z-[-1] border-slate-500 border-[1px]'>
                            <p className='flex items-center justify-center h-full'>برگشت</p>
                          </div>
                        </div>

                        <MapComponent
                          id="map"
                          options={{
                            mapKey: "web.289f3d07d19d45fa9d579d1369a01b2c",
                            mapType: MapTypes.neshanVector,
                            mapTypeControllerOptions: {
                              show: false,
                            },
                          }}
                        />
                        <div className={`absolute drop-shadow-2xl shadow-black bottom-0 max-w-[1659px] md:w-full bg-white w-[90%] ${goToOptionPage ? "hidden" : "block"} ${openMessagePage && "hidden"} ${showOption ? "h-[95%] md:h-full md:rounded-none transition-height duration-[0.4s] z-50" : "h-[300px] transition-height duration-[0.4s]"} rounded-t-[40px] px-[20px] pb-5 border-[1px] border-black 
                          md:px-3 `}>
                          <div className='grid grid-cols-3 h-full text-[32px] md:text-[14px]'>

                            <div onClick={() => setShowOption(!showOption)} className={`col-span-3 pt-5 col-start-4 col-end-1 row-start-1 flex justify-center items-center cursor-pointer w-full h-[35px]`}>
                              <img className={`${showOption ? 'rotate-180' : 'rotate-0'}`} src={ArrowIcon} />
                            </div>

                            <h2 className={`flex items-end row-start-1 col-start-4 col-end-1 mx-auto mt-[40px] text-[44px] text-greyFont font-bold text-nowrap 
                            md:text-[16px] md:font-normal ${showOption ? "md:col-start-3 md:col-end-2 md:text-[22px]" : "md:col-start-2 md:col-end-1"}`}>{carInfo.name ? carInfo.name : "-----" + " " + carInfo.color ? carInfo.color : "-----"}</h2>

                            <div className={`w-full flex justify-end items-center row-start-2 col-start-4 col-end-3
                               md:col-end-2 ${showOption ? "md:row-start-2 md:items-center:" : "md:row-start-1 md:items-end"}`}>
                              <div dir='ltr' className='border-[1px] border-greyFont rounded-[10px] w-[248px] h-[55px] flex items-center
                                md:w-[160px] md:h-[40px] md:text-[16px]'>
                                <div className='w-[70%]' dir='rtl'>
                                  {/* <p className='text-center border-r-[1px] border-greyFont'>۱۵۴ س ۶۳</p> */}
                                  <p className='text-center border-r-[1px] border-greyFont'>{carInfo.plate_code?.part3} {carInfo.plate_code?.part2} {carInfo.plate_code?.part1}</p>
                                </div>

                                <div className='w-[30%] flex flex-col items-center leading-6 md:leading-tight'>
                                  <p className='text-[16px] md:text-[6px]'>ایران</p>
                                  <p>{carInfo.plate_code?.part4}</p>
                                </div>
                              </div>
                            </div>

                            <div className={`flex items-center row-start-2}`}>
                              <img src={DriverProfile} alt='' className='w-[70px] h-[70px] ml-[16px] md:w-[50px]' />
                              <p className={`text-greyTextColor text-greyFont text-nowrap `}>{driverInfo.full_name ? driverInfo.full_name : "----------"}</p>
                            </div>

                            <div className={`row-start-3 w-full col-start-4 col-end-3 flex items-center  justify-end ${showOption ? "hidden" : "flex"}
                          md:row-start-2`}>
                              <p dir='ltr' className='text-greyFont font-bold'> {travelPrice.toLocaleString("fa")} تومان
                                <span dir='rtl' className='font-extrabold'>هزینه :</span></p>
                            </div>

                            <div dir='ltr' onClick={() => navigate("/messagePage")} className={`flex row-start-3 gap-x-4 cursor-pointer h-fit ${showOption ? "col-start-1 col-end-3 justify-end items-start" : "col-start-3 col-end-1 justify-start items-center"}
                          md:justify-center md:col-start-4 md:col-end-1 md:w-full`}>
                              <div className='flex items-center py-[8px] px-[16px] bg-lightBlue rounded-[10px] w-[376px] justify-center 
                             md:w-[80%]'>
                                <p dir='rtl' className='bg-transparent placeholder:text-darkBlue placeholder:font-bold
                             border-none outline-none text-darkBlue w-[280px] md:w-full'>پیام خود را بنویسید...</p>
                                <img src={Message} className='ml-[18px] w-[30px]' />
                              </div>

                              <div className='rounded-full bg-lightBlue text-darkBlue w-[60px] h-[60px] flex items-center justify-center
                           md:w-[40px] md:h-[40px]'>
                                <img src={Phone} className='md:w-[20px]' />
                              </div>
                            </div>


                            <div className={`flex items-start row-start-6 col-start-4 col-end-1
                           md:w-full md:justify-center md:items-end  ${showOption === false ? "hidden" : "block"}`}>
                              <button onClick={cancelTheTravel} className={`bg-lightPink w-[300px] md:w-full h-[60px] md:h-[40px] text-darkPink rounded-[10px]`}>لغو سفر</button>
                            </div>
                          </div>

                        </div>
                      </div>
                    )
                  }
                </>

              )
            }


            {/* active travel  */}

          </>
        )
      }


      {/* modal for login  */}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        style={customStyles}
        className="modalMobile"
      >

        <div className=''>
          <button className="custom-modal-close" onClick={handleCancel}>
            &times;
          </button>
          {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
        </div>
      </Modal>
    </div>
  )
}

export default Trip


