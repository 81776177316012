import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    userInfo: {
        name: '',
        idCode: '',
        birthDate: '',
        gender: '',
        address: '',
        status: '',
        mobile: '',
        accessToken: localStorage.getItem('accessToken'),
        loadingProfile: true,
    },
    error: null,
    loginInfo: {
        loginApi: false
    },

    travelInfo: {
        travelId: ''
    },
};
const UserInformation = createSlice({
    name: 'userinformation',
    initialState,
    reducers: {
        setUserInformation: (state, action) => {
            state.userInfo = action.payload;
            state.loginApi = action.payload;
            state.error = null;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});


export const {setUserInformation: setUser, setError: setHandleError} = UserInformation.actions;
export default UserInformation.reducer;
