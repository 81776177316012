import React, { useContext, useState, useEffect } from 'react';
import DriverProfile from './public/driverProfile.svg';
import { useDispatch, useSelector } from "react-redux";
import SendIcon from './public/SendIcon.svg';
import { StateApp } from '../context/Context';
import axios from 'axios';
import { url } from '../url';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { GiCarWheel } from "react-icons/gi";
import { TbMapPinFilled } from "react-icons/tb";
import Modal from "react-modal";
import carMapic from './public/car-service-svgrepo-com.svg'
import LoginRegister from '../Login/LoginRegister';
import SuccessLogin from '../Modal/Success Login';

const ChatPage = () => {
  const defaultMessages = ["تماس میگیرم", "الان میام", "در مبدا منتظرتونم.", "چند دقیقه دیگه میرسید؟", "لطفا چند دقیقه در مبدا منتظر بمانید."];
  const [message, setMessage] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const { travelId } = useContext(StateApp);
  const { driverInfo, setDriverInfo } = useContext(StateApp);
  const { userInfo, error } = useSelector((state) => state.userinformation);
  const [passenderId, setPassengerId] = useState("");
  const [driverId, setDriverId] = useState("");
  const { accessToken } = userInfo;
  const [tripId, setTripId] = useState("");
  const [errHandle, setErrHandle] = useState(false);
  const [noMessages, setNoMessages] = useState(true)
  const [reloadAnimation, setReloadAnimation] = useState(false);
  const [networkConnection, setNetworkConnection] = useState(false)
  const [apiRecall, setApiRecall] = useState(false);
  const [updateMessages, setUpdateMessages] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noToken, setNoToken] = useState(false)
  const [noActiveTravel, setNoActiveTravel] = useState(false)

  const {
    carInfo, setCarInfo, loading,
    setLoading, openLogin, showSuccessLogin,
    updateUserName_header, setUpdateUserName_header,
    goToOptionPage, setGoToOptionPage
  } = useContext(StateApp)

  let today = new Date().toLocaleDateString('fa-IR', { month: 'long', day: 'numeric' });

  let navigate = useNavigate();

  const tryAgain = () => {
    setTimeout(() => {
      setApiRecall(prev => !prev)
      setReloadAnimation(false);
    }, 3000);
    setReloadAnimation(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      outline: 'none',
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };

  useEffect(() => {
    setNoActiveTravel(false)
    setNetworkConnection(false)
    setNoToken(false)
    axios.get(`${url}/travel/v1/get-active-travel-passenger`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }).then((res) => {
      setDriverInfo(res.data?.data?.driver_snapshot);
      setCarInfo(res.data?.data?.car_snapshot);
      setPassengerId(res.data?.data?.passenger?._id);
      setDriverId(res.data?.data?.driver?._id);
      setTripId(res.data?.data?._id);
      setNoActiveTravel(false)
      setNetworkConnection(false)
      setNoToken(false)
    })
      .catch(err => {
        if (err?.response?.data?.status === 404)
          setNoActiveTravel(true)
        if (err?.message === "Network Error") {
          setNetworkConnection(true)
        }
        if (err?.response?.data)
          setNoToken(true)
      });

  }, [apiRecall, showSuccessLogin])

  useEffect(() => {
    setNoMessages(false)
    setNetworkConnection(false)
    setNoToken(false)
    axios.get(`${url}/travel-message/v1/get-passenger-messages-active`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(res => {
      console.log('messages : ', res.data);
      setNoMessages(false)
      setNetworkConnection(false)
      setNoToken(false)
      setAllMessages(res.data.data)
    }).catch(err => {
      console.log(err);
      if (err?.response?.data?.status === 404)
        setNoMessages(true)
      if (err?.message === "Network Error") {
        setNetworkConnection(true)
      }
      if (err?.response?.data)
        setNoToken(true)
    })
  }, [apiRecall,showSuccessLogin])

  const sendMessage = () => {
    if (message.trim()) {
      setMessage("");
    }
    // Send message
    axios.post(`${url}/travel-message/v1/passenger-send-message`,
      {
        message: message,
        travel_id: tripId,
        passenger_id: passenderId
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(res => {
        console.log("send message : ", res.data);
        setUpdateMessages(prev => !prev)
      })
      .catch(error => {
        console.log('send message error : ', error);

      });
  };

  console.log(networkConnection)

  return (
    <div className='h-screen flex flex-col justify-between relative'>
      {
        // ---- no internet ----
        networkConnection ? (
          <>
            <div className='flex items-center justify-center py-10 px-5'>
              <div className='flex items-center gap-5 w-full flex-col'>
                <div className='errorSign bg-red-600 flex items-center justify-center'>
                  <div className='errorSign2 bg-red-100 text-red-600 text-[84px] flex items-center justify-center'>!</div>
                </div>
                <p className='text-red-600 text-center'>خطا در برقراری ارتباط! <br className='md:block hidden' /> لطفا اینترنت خود را بررسی کنید...</p>
                <div className={` ${reloadAnimation ? "flex items-center gap-x-2" : "invisible"}`}>
                  <p>منتظر بمانید...</p>
                  <GiCarWheel className={`${reloadAnimation && "animate-spin text-[25px]"}`} />
                </div>
                <button className=' text-[18px] border-2 border-darkBlue text-darkBlue hover:bg-darkBlue hover:text-white rounded-lg py-2 px-4 mt-5' onClick={tryAgain}>تلاش مجدد</button>
              </div>
            </div>
          </>
        ) : (
          <>
            {
              // ---- no token ----
              noToken ? (
                <div className={`w-full h-full flex flex-col items-center text-[30px] flex`}>
                  <div className='flex flex-col gap-x-5 items-center mt-[50px]'>
                    <div className='text-[18px] text-center'>
                      <p className='mb-5 text-[24px] font-bold'>
                        مارانکو سفری مطمئن !
                      </p>
                      <p className='mb-5 px-10'>
                        با مارانکو به هرجایی که خواستید به راحتی سفر کنید
                      </p>
                    </div>
                    <img src={carMapic} />

                    <div className='flex flex-col mt-10'>
                      <div className='flex gap-x-5 items-center justify-center h-full '>
                        <p className='text-[24px] font-bold'>
                          شما وارد حساب کاربری نشده اید !
                        </p>
                      </div>

                      <div className='flex gap-x-2 md:flex-col w-full'>
                        <button onClick={() => setIsModalOpen(true)} className='py-2 md:w-[40%] text-[18px] mt-5 rounded-lg border-darkBlue text-darkBlue border-2 
                      hover:bg-darkBlue hover:text-white hover:duration-[0.4s] mx-auto w-full'>ثبت نام</button>

                        <button onClick={() => navigate("/passengersTrips")} className='py-2 md:w-[40%] text-[18px] mt-5 md:mt-2 rounded-lg border-red-500 text-red-500 border-2 
                      hover:bg-red-500 hover:text-white hover:duration-[0.4s] mx-auto w-full'>برگشت</button>
                      </div>

                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {
                    // ---- no active travel ----

                    noActiveTravel ? (
                      <div className={`w-full h-full flex flex-col items-center text-[30px] flex`}>
                        <div className='flex flex-col gap-x-5 items-center mt-[50px]'>
                          <div className='text-[18px] text-center'>
                            <p className='mb-5 text-[24px] font-bold'>
                              مارانکو سفری مطمئن !
                            </p>
                            <p className='mb-5 px-10'>
                              با مارانکو به هرجایی که خواستید به راحتی سفر کنید
                            </p>
                          </div>
                          <img src={carMapic} />

                          <div className='flex flex-col mt-10'>
                            <div className='flex gap-x-5 items-center justify-center h-full '>
                              <TbMapPinFilled className="animate-bounce text-[45px] " />
                              <p className='text-[24px] font-bold'>
                                در انتظار تایید راننده
                              </p>
                            </div>
                            <button onClick={() => navigate("/passengersTrips")} className='py-2 md:w-[40%] text-[18px] mt-5 rounded-lg border-red-500 text-red-500 border-2 
                            hover:bg-red-500 hover:text-white hover:duration-[0.4s] mx-auto w-full'>برگشت</button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className='flex flex-col items-center bg-transparent'>
                          <div dir='ltr' className='flex flex-col bg-transparent items-center justify-center w-full px-3 pb-[30px] border-b-4 border-slate-200'>
                            <h2 className='text-greyFont text-[22px] my-[20px]'>{carInfo?.name} {carInfo?.color}</h2>
                            <div className='flex justify-between items-center w-full'>
                              <div className={`flex justify-end items-center row-start-2 col-start-4 col-end-3 md:col-end-2`}>
                                <div dir='ltr' className='border-[1px] border-greyFont w-[200px] h-[48px] flex items-center md:w-[130px] md:h-[40px] md:text-[16px]'>
                                  <div className='w-[70%]' dir='rtl'>
                                    <p className='text-center border-r-[1px] border-greyFont'>{carInfo?.plate_code?.part3} {carInfo.plate_code?.part2} {carInfo.plate_code?.part1}</p>
                                  </div>
                                  <div className='w-[30%] flex flex-col items-center leading-2 md:leading-tight'>
                                    <p className='text-[14px] md:text-[6px]'>ایران</p>
                                    <p className=''>{carInfo.plate_code?.part4}</p>
                                  </div>
                                </div>
                              </div>
                              <div className='flex items-center gap-x-4 md:gap-x-2'>
                                <p>{driverInfo.full_name}</p>
                                <img src={DriverProfile} className='rounded-full w-[50px] h-[50px] md:w-[40px] md:h-[40px]' />
                              </div>
                            </div>
                          </div>
                          <p className={`text-center mt-[10px] h-fit w-[80%] text-greyFont pb-5 ${noMessages ? "block" : "hidden"}`}>
                            اینجا میتوانید به راننده پیام دهید یا پیام های راننده را دریافت کنید.
                          </p>
                          {/* message box  */}
                          <div className='flex flex-col items-center justify-end h-full w-full relative px-3'>
                            <p className='w-fit bg-purple-200 px-4 py-1 rounded-lg sticky top-5 mt-2'><span className={`text-purple-900 ${allMessages === null ? "hidden" : "block"}`}>{today}</span></p>
                            <div id='messagePanel' className='w-full h-full flex flex-col justify-start mb-5 overflow-y-auto'>
                              {
                                allMessages.map((item, index) => (
                                  <div key={index} className={`w-full flex ${item.sender.driver && "justify-end"}`}>
                                    <div className={`flex-col p-5 w-[300px] mb-2 my-2 h-auto ${item.message.length > 40 ? "rounded-2xl" : "rounded-full"} 
                                      ${item.sender.driver ? " rounded-bl-none text-black bg-lightBlue" : " rounded-br-none bg-darkBlue text-white"} gap-y-2`}>
                                      <p className=' break-words w-full pr-2'>{item.message}</p>
                                      <div className={`w-full flex ${item.sender.driver ? "justify-end" : "justify-start"} mb-[-10px] mt-5`}>
                                        <p className={`${item.sender.driver ? "text-slate-500" : "text-slate-300"} text-[14px]`}>
                                          {moment.tz(item.createdAt, 'Asia/Tehran').format('HH:mm')}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>

                        <div className='w-full pb-5 px-5 mt-5 '>
                          <div className='w-[90%] mx-auto border-t-2 border-gray-400 md:w-full'>
                            <div id='messageMenu' className='flex items-center gap-x-2 overflow-y-hidden h-[70px] overflow-x-auto w-full '>
                              {
                                defaultMessages.map((message, index) => (
                                  <p onClick={() => setMessage(message)} key={index} className='bg-lightBlue text-darkBlue p-2 cursor-pointer rounded-lg w-fit text-nowrap'>
                                    {message}
                                  </p>
                                ))
                              }
                            </div>

                            <div className='flex items-center gap-x-2 w-full justify-start mb-5'>
                              <div className='flex items-center gap-x-2'>
                                {/* send button  */}
                                <div onClick={sendMessage} className='h-[40px] bg-lightBlue shadow-slate-500 shadow-sm max-w-[40px] w-[40px] rounded-full flex items-center justify-center cursor-pointer'>
                                  <img src={SendIcon} className='w-[24px] cursor-pointer' />
                                </div>
                              </div>
                              <input type='text' onChange={(e) => setMessage(e.target.value)} value={message}
                                className='h-[40px] flex-grow text-right bg-transparent rounded-[10px] px-5 border-r-2 border-slate-400 border-none outline-none '
                                placeholder='پیام خود را بنویسید...' />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                </>
              )
            }
          </>
        )
      }

      {/* login modal  */}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        style={customStyles}
        className="modalMobile"
      >
        <div className=''>
          <button className="custom-modal-close" onClick={handleCancel}>
            &times;
          </button>
          {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
        </div>
      </Modal>
    </div>
  );
}

export default ChatPage;
