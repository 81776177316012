import React, { useContext, useEffect, useState } from "react";
import Header2 from "../header/Header2";
import paymentImg from "./public/PaymentInfo.png";
import Footer from "../footer/Footer";
import GetProfile from "../profile/GetProfile";
import { useNavigate } from "react-router-dom";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker, { Calendar } from "react-multi-date-picker"
import { StateApp } from "../context/Context";
import { useSelector } from "react-redux";
import axios from "axios";
import { url } from "../url";


const Payment = () => {
    const navigate = useNavigate();
    const { travelData, setTravelData } = useContext(StateApp);
    const travelInfo = useSelector((state) => state.travelinformation.travelInfo);
    const { travelId } = travelInfo;
    const { isLocked, setIsLocked } = useContext(StateApp);
    const [token, setToken] = useState("");
    const { transactionPage, setTransactionPage } = useContext(StateApp);
    const [allPaymentDetails, setAllPaymentDetails] = useState([])

    const tk = localStorage.getItem('accessToken');

    useEffect(() => {
        axios.get(`${url}/travel/v1/get-detail-of-pay/${travelId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(res => {
            setAllPaymentDetails(res.data.data);
        })

    }, [])


    useEffect(() => {
        const storedData = localStorage.getItem('travelData');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setTravelData(parsedData);
        }
        else {
            navigate("/")
        }
    }, []);

    const handleClick = () => {
        axios.post(`${url}/transaction/v1/request-transaction`,
            {
                travel_id: travelId,

            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            }).then(res => {
                setToken(res.data.data.token)
                setIsLocked(true);
                localStorage.removeItem('travelData');
            })
            .catch(err => {
            });


    };
    if (token !== "")
        window.location.href = `https://www.zarinpal.com/pg/StartPay/${token}`;

    const handleCancel = () => {
        localStorage.removeItem('travelData')
        navigate("/")
        setIsLocked(false)
    };

    return (
        <div>
            <div className="md:hidden">
                <Header2 />
                <GetProfile />
            </div>
            <div className="flex flex-col gap-[42px] md:gap-0">
                <div className="md:h-[70px] md:flex md:flex-col md:justify-center md:items-center">
                    <p className="text-black text-[26px] mr-[105px] mt-[42px] md:mr-[0px] md:mt-[0px]">
                        صفحه پرداخت
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center mb-24">
                    <div className="w-[87%] md:w-[95%] h-[100%] bg-white rounded-[20px] shadow-[#00000040]">
                        <div className="w-[100%] h-[100%] flex flex-col">
                            <div className="justify-center flex">
                                <img src={paymentImg} alt="پرداخت" className="w-[350px] h-[350px]" />
                            </div>
                            {travelData && (
                                <div className="flex flex-col w-[90%] mx-auto gap-4">
                                    <div
                                        className="p-2 pr-5 pl-5 rounded-[20px] bg-gray-200 flex justify-between items-center w-[100%] mx-auto mb-4">
                                        <div className="flex gap-2">
                                            <p className="font-bold text-[16px]">مبدا :</p>
                                            <p className="font-bold text-[16px]">{travelData.origin_city.name}</p>
                                        </div>
                                        <div className="flex-grow mx-4 border-t-4 border-gray-600 border-dotted"></div>
                                        <div className="flex gap-2 items-center">
                                            <p className="font-bold text-[16px]">مقصد :</p>
                                            <p className="font-bold text-[16px]">{travelData.destination_city.name}</p>
                                        </div>
                                    </div>
                                    <div className="w-[100%] flex justify-between md:flex-col md:justify-center items-center gap-3 mb-5">
                                        <div
                                            className="gap-2 bg-gray-200 flex w-[200px] p-2 rounded-[20px] justify-center items-center">
                                            <p>ساعت سفر:</p>
                                            <p className="text-[16px]">{travelData.travel_hour_time}</p>
                                        </div>
                                        <div
                                            className="text-[16px] gap-2 bg-gray-200 flex w-[180px] p-2 rounded-[20px] justify-center items-center">
                                            <p className="text-nowrap">تاریخ سفر:</p>
                                            <DatePicker
                                                calendar={persian}
                                                locale={persian_fa}
                                                value={travelData.travel_date}
                                                readOnly
                                                style={{ backgroundColor: "transparent", outline: "none", border: "none", width: "10px" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between flex-wrap gap-x-10 ">
                                        <div className="text-right md:border-b-2 border-gray-300 md:w-full pb-5">
                                            <div className="w-full flex flex-col gap-2">
                                                <div className="flex justify-between gap-3">
                                                    <p>نام مسافر:</p>
                                                    <p>{allPaymentDetails.passenger_snapshot?.full_name}</p>
                                                </div>
                                                <div className="flex justify-between">
                                                    <p className="ml-2"> کدملی :  </p>
                                                    <p>{allPaymentDetails.passenger_snapshot?.national_code}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" text-left flex-col flex gap-2 pt-5 md:w-full">
                                            <div className="flex justify-between ">
                                                <p>هزینه سفر:</p>
                                                <span className="text-[#00D222]">{allPaymentDetails.price}</span>
                                            </div>
                                            <div className="flex justify-between gap-10">
                                                <p>پرداخت هزینه اولیه:</p>
                                                <span
                                                    className="text-[#00D222]">{allPaymentDetails.priceToPay}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div
                                className="flex flex-row justify-between m-[50px] md:flex-col-reverse md:mr-0 md:ml-0 md:mb-[12px] md:items-center">
                                <button onClick={handleCancel}
                                    className="bg-[#EC4848] md:w-[90%] w-[437px] h-[50px] rounded-[10px] m-2">
                                    <p className="text-[22px] text-white">انصراف</p>
                                </button>
                                <button onClick={handleClick}
                                    className="bg-[#65DF9D] md:w-[90%] w-[437px] h-[50px] rounded-[10px] m-2">
                                    <p className="text-[22px] text-white">پرداخت</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Payment;
