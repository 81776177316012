import React, { useState } from 'react'
import stopTime from './public/stopTimeIcon.svg'
import backArrow from './public/Arrow 4.svg'
import dropDownArrow from './public/arrowIcon.svg'
import { useContext } from 'react'
import { StateApp } from '../context/Context'
import clock from './public/clock.svg'
import { useNavigate } from 'react-router-dom'

const StopOnWay = () => {

  const { goToOptionPage, setGoToOptionPage } = useContext(StateApp);
  const [timeMenue, setTimeMenue] = useState(false);

  let navigate = useNavigate(null);

  const wastedTimes = [
    {
      "time": "۱ ساعت",
      "cost": 20000
    },
    {
      "time": "۲ ساعت",
      "cost": 40000
    },
    {
      "time": "۳ ساعت",
      "cost": 60000
    },
    {
      "time": "۴ ساعت",
      "cost": 80000
    },

  ]

  return (
    <div className='bg-white w-full flex flex-col pt-5 items-center justify-center h-full overflow-y-auto'>
      <div className='grid grid-cols-3 w-full'>
        <img src={backArrow} className='mr-[50px]' onClick={() => navigate(-1)} />
        <div className='flex items-center justify-center'>
          <h2 className='col-start-4 text-nowrap col-end-5 text-[22px] font-bold '>گزینه های سفر</h2>
        </div>
      </div>

      <div className='w-full flex items-center justify-center'>
        <img src={stopTime} className='w-full max-w-[400px]' />
      </div>

      <div className='w-full h-full px-5 flex flex-col justify-between'>
        <div className='flex flex-col items-start justify-center'>
          <div onClick={()=>setTimeMenue(timeMenue?false:true)} dir='ltr' className='flex justify-between items-center w-full cursor-pointer pb-2 border-b-2 border-slate-300'>
            <img src={dropDownArrow} className={`${timeMenue ? "rotate-180 transition duration-[0.4s]" : "rotate-0 transition duration-[0.4s]"}`} />
            <p className='font-semibold'>توقف در مسیر</p>
          </div>

          <div className='h-full w-full'>
            {/* stop times */}
            <div className={`${timeMenue?"h-full transition-height duration-[0.4s]" : "h-0 overflow-hidden transition-height duration-[0.4s]"} w-full flex flex-col mt-5 gap-y-5 items-center justify-start`}>
              {
                wastedTimes.map((item,index)=>(
                  <div className={`flex items-center border-b-2 border-slate-300 w-full gap-x-2 last-of-type:border-none pb-2`}>
                    <img src={clock}/>
                    <p>{item.time}</p>
                  </div>
                ))
              }
            </div>

          </div>
        </div>

        <div>
          <div className='flex items-center justify-between border-b-2 pb-[40px] border-slate-300'>
            <p>تومان ۱,۲۰۰,۰۰۰</p>
            <p>هزینه سفر</p>
          </div>

          <div className='flex items-center justify-between mb-5 mx-auto mt-[24px] w-[80%] '>
            <button>تایید</button>
            <button className='text-red-600' onClick={() => navigate("/trip")}>انصراف</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StopOnWay
