import React, { useState, useContext } from "react";
import Header from "./header/Header";
import Body from "./body/Body";
import Footer from "./footer/Footer";
import { StateApp } from "./context/Context";
import Navbar from "./header/Navbar";
import GetProfile from "./profile/GetProfile";
import { AnimatePresence, motion } from "framer-motion";
import RulesPage from "./RulesPage/RulesPage";

const Main = () => {
    const { ShowNavbar, setShowNavbar } = useContext(StateApp);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleNavbar = () => {
        setShowNavbar(!ShowNavbar);
    };

    return (
        <div className="relative">
            {ShowNavbar && (
                <div className="fixed h-[100vh] inset-0 z-40 bg-black bg-opacity-60" onClick={handleNavbar}></div>
            )}
            <div className="fixed z-50 grid grid-cols-12 w-full">
                <div className="col-span-8">
                    <AnimatePresence>
                        {ShowNavbar && (
                            <motion.div
                                initial={{ x: window.innerWidth }}
                                animate={{ x: 0 }}
                                exit={{ x: window.innerWidth }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 350,
                                    damping: 35,
                                    duration: 0.5
                                }}
                                key={ShowNavbar}
                                className="h-full"
                            >
                                <Navbar />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div onClick={handleNavbar} className="col-span-4"></div>
            </div>
            <div>
                <GetProfile />
                <Header />
                <Body />
                <Footer />
            </div>
        </div>
    );
};
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register(`/firebase-messaging-sw.js`)
        .then((registration) => {
            console.log(
                "Service Worker registration successful with scope: ",
                registration.scope
            );
        })
        .catch((err) => {
            console.error("Service Worker registration failed: ", err);
        });
}

export default Main;
