import React, {useContext} from "react";
import picture from "../Login/public/picture.png";
import Login from "./Login";
import CreateAccount from "./CreateAccount";
import {StateApp} from "../context/Context";
import "./CreateAccount.css";

const LoginRegister = () => {
    const {Register, setRegister} = useContext(StateApp);

    const handleCreateAccountClick = () => {
        setRegister(!Register);
    };

    return (
        <div className=" rounded-[20px] md:w-full w-[800px] h-2/4 grid grid-cols-10 bg-white md:grid-cols-1">
            <div
                className={`rounded-[20px] md:right-0 md:grid-cols-1 relative right-4 flex flex-col justify-center items-center col-span-7 bg-white z-50 `}
            >
                {Register ? <Login/> : <CreateAccount/>}
            </div>
            <div className="md:hidden col-span-3 flex justify-center items-center bg-transparent ">
                <img className="bg-transparent h-auto w-auto rounded-l-[20px]" src={picture}/>
            </div>
        </div>
    );
};

export default LoginRegister;
