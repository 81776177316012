import React, { useState } from "react";
import Image from "../Login/public/picture.png";
import { Link } from "react-router-dom";
import Login from "./Login";
import "../Login/Login.css"
import { requestForToken } from "../firebase";


const CreateAccount = () => {
    const handleCreateAccount = (e) => {
        e.preventDefault();
    };

    return (
        <div className="flex flex-col">
            <div
                className="flex flex-col justify-center items-center md:w-full md:flex md:justify-start md:items-center">
                <p className="text-[26px] font-[800] text-[#100E34]">
                    ساختن حساب کاربری
                </p>
                <div className=" w-full h-full flex flex-col md:flex md:justify-center md:items-center md:flex-col ">
                    <div className="form">
                        <div className="inputDiv">
                            <input
                                type="text"
                                id="ّFirstAndLast"
                                className="form__input"
                                autoComplete="off"
                                placeholder=" "
                            />
                            <label htmlFor="ّFirstAndLast" className="form__label">
                                نام و نام خانوادگی
                            </label>
                        </div>

                        <div className="inputDiv">
                            <input
                                type="text"
                                id="ID"
                                className="form__input"
                                autoComplete="off"
                                placeholder=" "
                            />
                            <label htmlFor="ID" className="form__label">
                                کدملی
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" w-full flex justify-center items-center ">
                <button
                    type="submit"
                    className="bg-[#4F48EC] md:w-[90%] md:h-[50px] md:rounded-[10px] w-[600px] h-[65px] mt-[40px] rounded-[10px]"
                >
                    <p className="text-white text-[26px] md:text-[16px]">ساختن حساب</p>
                </button>
            </div>
        </div>
    );
};
export default CreateAccount;
