import React, { useState, createContext, useContext, useEffect } from 'react';
import Cookies from "js-cookie";

export const StateApp = createContext();

const States = ({ children }) => {
    const [Register, setRegister] = useState(true);
    const [openLogin, setOpenlogin] = useState(true);
    const [handleVerification, setHandleVerification] = useState(false);
    const [loading, setLoading] = useState();
    const [ShowNavbar, setShowNavbar] = useState(false);
    const [showSuccessLogin, setshowSuccsessLogin] = useState(false);
    const [getAccessToken, setaccessToken] = useState();
    const [completedProfile, setcompletedProfile] = useState();
    const [selectedCarId, setSelectedCarId] = useState(null);
    const [userMobile, setUserMobile] = useState("");
    const [tripPice, setTripPrice] = useState(null);
    const [isLocked, setIsLocked] = useState(false);
    const [travelData, setTravelData] = useState(null);
    const [driverInfo, setDriverInfo] = useState([]);
    const [carInfo, setCarInfo] = useState([]);
    const [plateCode, setPlateCode] = useState(null);
    const [latitude, setLatitude] = useState("+32.6");
    const [longitude, setLongitude] = useState("+51.6");
    const [transactionPage, setTransactionPage] = useState("");

    useEffect(() => {
        const savedTravelData = localStorage.getItem('travelData');
        if (savedTravelData) {
            setTravelData(JSON.parse(savedTravelData));
        }
    }, []);

    const contextValue = {
        latitude, setLatitude,
        longitude, setLongitude,
        Register, setRegister,
        driverInfo, setDriverInfo,
        carInfo, setCarInfo,
        travelData, setTravelData,
        openLogin, setOpenlogin,
        handleVerification, setHandleVerification,
        loading, setLoading,
        ShowNavbar, setShowNavbar,
        showSuccessLogin, setshowSuccsessLogin,
        setaccessToken, getAccessToken,
        completedProfile, setcompletedProfile,
        userMobile, setUserMobile,
        selectedCarId, setSelectedCarId,
        tripPice, setTripPrice,
        plateCode, setPlateCode,
        isLocked, setIsLocked,
        transactionPage, setTransactionPage,
    };

    return (
        <StateApp.Provider value={{ ...contextValue }}>
            {children}
        </StateApp.Provider>
    );
};
export default States;
export const AppState = () => {
    return useContext(StateApp);
};


