import React, { useContext, useEffect, useState } from "react";
import Header2 from "../header/Header2";
import "../PassengerInformation/PassengerInformation.css"
import { useSelector } from "react-redux";
import userInformation from "../slices/UserInformation";
import GetProfile from "../profile/GetProfile";
import loading from "../Loading/Loading";
import { message, Skeleton, notification } from 'antd';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { BiChevronDown } from "react-icons/bi";
import Header from "../header/Header";
import axios from "axios";
import { setTravel } from "../slices/Travelinfo";
import { useNavigate } from "react-router-dom";
import { StateApp } from "../context/Context";
import Loading from "../Loading/Loading";
import { url } from "../url";


const PassengerInformation = () => {
    const { userInfo, error } = useSelector((state) => state.userinformation);
    const { name, idCode, birthDate, gender, address, status, accessToken, mobile, loadingProfile } = userInfo;
    const [showSupport, setShowSupport] = useState(false);
    const travelInfo = useSelector((state) => state.travelinformation.travelInfo);
    const { travelId, origin, destination } = travelInfo;
    const [passengerName, setPassengerName] = useState("")
    const [nationalCode, setNationalcode] = useState("")
    const [homeAddress, setHomeAddress] = useState("")
    const [mobilePhone, setMobilePhone] = useState("")
    const [FatherName, setFatherName] = useState("")
    const[checkboxValue,setCheckboxValue] = useState(false)

    const { loading, setLoading } = useContext(StateApp);

    useEffect(() => {
        setPassengerName(name)
        setHomeAddress(address)
        setMobilePhone(mobile)
        setNationalcode(idCode)
    }, [name])
    const handleSupport = () => {
        setShowSupport(!showSupport)
    }
    const navigate = useNavigate();
    // ------ log commands ------


    const handleSubmit = () => {
        setLoading(true);
        const requestData = {
            travel_id: travelId,
            full_name: passengerName,
            father_name: FatherName,
            national_code: nationalCode,
            address: homeAddress,
            mobile: mobilePhone
        };
        axios.put(`${url}/travel/v1/insert-passenger-details`, requestData, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => {
                setLoading(false);

                if (response.status === 200) {
                    navigate("/address")
                }
            })
            .catch(error => {
                console.error("Error creating travel request:", error);
                setLoading(false);
                if (error.response.status !== 200)
                    notification.error({
                        message: error.response.data.message,
                        description: "",
                        placement: 'topRight',
                    });
            });
    };

    return (
        <div>
            <div className="md:hidden">
                <Header2 />
            </div>
            <GetProfile />
            <div className="flex flex-col gap-[42px] md:h-screen md:gap-0">
                <div className=" md:h-[70px] md:flex md:flex-col md:justify-center md:items-center">
                    <p className="text-black text-[26px] md:text-[22px] mr-[105px] mt-[42px] md:mr-[0px] md:mt-[0px] ">
                        ثبت اطلاعات مسافر
                    </p>
                </div>
                <div className="flex flex-col h-full justify-center md:pb-0 items-center pb-[80px]">
                    <div className="w-[87%] h-full md:w-[95%] flex flex-col justify-between pb-10 bg-white rounded-[20px] shadow-[#00000040]">
                        <div>
                            {
                                loadingProfile ? (
                                    <div className="gap-[30px] md:gap-4 flex flex-col">
                                        <div
                                            className=" relative md:grid-cols-1 grid grid-cols-3 gap-4 justify-center items-center mt-[46px]">
                                            <div className=" md:absolute relative w-[90%] h-[60px] justify-self-center">
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        className="md:!w-full"
                                                        style={{ height: "30px", width: "400px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" relative w-[90%] h-[60px] justify-self-center">
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        className="md:!w-full"
                                                        style={{ height: "30px", width: "400px" }}
                                                    />
                                                </div>
                                            </div>

                                            <div className=" relative w-[90%] h-[60px] justify-self-center">
                                                <div>
                                                    <Skeleton.Input
                                                        active
                                                        className="md:!w-full"
                                                        style={{ height: "30px", width: "400px" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className=" md:grid-cols-1 grid-cols-3 grid relative gap-4 justify-center items-center">
                                            <div className="col-span-2 flex justify-center">
                                                <div className="relative md:w-[90%] w-[95%] h-[60px] justify-self-center">
                                                    <div>
                                                        <Skeleton.Input
                                                            active
                                                            className="md:!w-full"
                                                            style={{ height: "30px", width: "850px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 md:col-span-2 flex justify-center items-center">
                                                <div className="relative w-[90%] h-[60px] justify-self-center">
                                                    <div>
                                                        <Skeleton.Input
                                                            active
                                                            className="md:!w-full"
                                                            style={{ height: "30px", width: "400px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="gap-[30px] md:gap-2 flex flex-col">
                                        <div
                                            className=" relative md:grid-cols-1 grid grid-cols-3 gap-4 md:gap-2 justify-center items-center mt-[46px] md:mt-[24px]">
                                            <div className=" relative w-[90%] h-[60px] justify-self-center">
                                                {/* passenger Name  */}
                                                <input
                                                    type="text"
                                                    id="FirstAndLast"
                                                    className="form_input"
                                                    autoComplete="off"
                                                    placeholder=" "
                                                    value={passengerName}
                                                    onChange={(e) => setPassengerName(e.target.value)}
                                                />
                                                <label htmlFor="FirstAndLast" className="form_label">
                                                    نام و نام خانوادگی</label>
                                            </div>
                                            <div className=" relative w-[90%] h-[60px] justify-self-center">
                                                <input
                                                    type="text"
                                                    id="IdNumber"
                                                    className="form_input"
                                                    autoComplete="off"
                                                    placeholder=" "
                                                    value={nationalCode}
                                                    onChange={(e) => setNationalcode(e.target.value)}
                                                />
                                                <label htmlFor="IdNumber" className="form_label">
                                                    کدملی </label>
                                            </div>
                                            <div className=" relative w-[90%] h-[60px] justify-self-center">
                                                <input
                                                    type="text"
                                                    id="FatherName"
                                                    className="form_input"
                                                    autoComplete="off"
                                                    placeholder=" "
                                                    onChange={(e) => setFatherName(e.target.value)}
                                                />

                                                <label htmlFor="FatherName" className="form_label">
                                                    نام پدر </label>
                                            </div>
                                        </div>

                                        <div
                                            className=" md:grid-cols-1 grid-cols-3 grid relative gap-4 md:gap-2 justify-center items-center">
                                            <div className="col-span-2 flex justify-center">
                                                <div className="relative md:w-[90%] w-[95%] h-[60px] justify-self-center">
                                                    <input
                                                        type="text"
                                                        id="phoneNumber"
                                                        className="form_input"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        value={homeAddress}
                                                        onChange={(e) => setHomeAddress(e.target.value)}
                                                    />
                                                    <label htmlFor="Address" className="form_label">
                                                        آدرس
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-span-1 md:col-span-2 flex justify-center items-center">
                                                <div className="relative w-[90%] h-[60px] justify-self-center">
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        className="form_input"
                                                        autoComplete="off"
                                                        placeholder=""
                                                        value={mobilePhone}
                                                        onChange={(e) => setMobilePhone(e.target.value)}
                                                    />
                                                    <label htmlFor="phone" className="form_label">
                                                        شماره موبایل
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                            {/* قوانین و مقررات  */}

                            <div className="mx-10 my-2">
                                <div className="flex items-center gap-x-2">
                                    <input type="checkbox" className="cursor-pointer" onChange={(e)=>setCheckboxValue(e.target.checked)}/>
                                    <p> تابع <span onClick={()=>navigate("/rules")} className="text-darkBlue underline cursor-pointer">قوانین و مقررات </span> مارانکو هستم</p>
                                </div>
                            </div>


                            <div className=" md:hidden p-6 w-[98%] ">
                                <div onClick={handleSupport} className="flex gap-2 items-center cursor-pointer w-fit">
                                    <p onClick={handleSupport} className="text-[#100E34] p-2 cursor-pointer text-[20px] font-[500]">
                                        خدمات پشتیبانی
                                    </p>
                                    <BiChevronDown
                                        style={{ fontSize: "28px", color: "#100E34" }}
                                        className={`transform transition-transform cursor-pointer duration-300 ${showSupport ? 'rotate-180' : 'rotate-0'}`}
                                    />
                                </div>
                                <div>
                                    <p className={`${showSupport ? "slide-down" : "slide-up invisible"} text-[#100E34] text-[16px] font-[500] mt-4 leading-[37px]`}>
                                        برنامه ما به عنوان یکی از شرکت‌هایی که خدمات رزرو تاکسی های بین شهری را
                                        ارائه
                                        می‌دهند در تمامی لحظات از مسافران حمایت می کنند و در صورتی که سواری
                                        نتواند به
                                        دلایلی
                                        مسیر را ادامه دهد شرکت در اسرع وقت خودروی دیگری را به مسافر رسانده تا
                                        بتواند سفر
                                        خود
                                        را بدون هیچگونه مشکلی ادامه دهد. توجه داشته باشید که شرکت‌های تاکسی های
                                        بین شهری
                                        خدماتی این چنینی را به صورت رایگان و بدون هیچگونه هزینه اضافی ارائه می
                                        دهند.
                                        مسافرین
                                        تاکسی های بین شهری می توانند هر گونه انتقاد، پیشنهاد، نظر یا شکایتی
                                        داشته باشند
                                        را
                                        از طریق سایت یا شماره‌های موجود با تیم پشتیبانی مستربلیط در میان
                                        بگذارند. به این
                                        ترتیب ما را در خدمت‌رسانی بهتر یاری خواهید کرد.
                                        در صورتی که مسافر کیف یا سایر وسایل خود را در تاکسی های بین شهری جا
                                        گذاشته باشد
                                        به
                                        راحتی می‌تواند به شرکتی که تاکسی متعلق به آن است مراجعه نمایند و وسایل
                                        خود را
                                        دریافت
                                        کند.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-cente mt-[40px]  md:mt-[20px]">
                            <button onClick={()=>{
                                if(checkboxValue){
                                    handleSubmit()
                                }
                                else{
                                    notification.error({
                                        message:"لطفا قوانین و مقررات را مطالعه نمایید !"
                                    })
                                    return null
                                }
                            }}
                                className="bg-[#4F48EC] md:w-[90%] w-[600px] h-[60px] md:h-[44px] rounded-[10px] flex items-center justify-center">
                                {loading ? (
                                    <Loading />
                                ) : (<div><p className="text-[26px] md:text-[18px] text-white">
                                    ثبت اطلاعات
                                </p></div>)}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PassengerInformation;
