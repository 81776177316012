import React from "react";
import Header2 from "../header/Header2";
import paymentImg from "./public/PaymentSuccess.png";
import Footer from "../footer/Footer";
import GetProfile from "../profile/GetProfile";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate();
    return (
        <div className="h-screen flex flex-col justify-between">
            <>
                <Header2 />
                <GetProfile />
                <div className="flex flex-col gap-[42px] md:gap-0">

                    <div className="flex flex-col justify-center items-center mb-24">
                        <div className="w-[87%] md:w-[95%] h-[100%] bg-white rounded-[20px] shadow-[#00000040] mt-10 ">

                            <div className=" md:h-[70px] flex items-center justify-center">
                                <p className="text-black text-[18px] mt-10">
                                    درخواست شما با موفقیت ثبت شد
                                </p>
                            </div>

                            <div className="hidden">
                                {
                                    setTimeout(() => {
                                        navigate("/passengersTrips")
                                    }, 2000)
                                }
                            </div>

                            <div className="w-[100%]  h-[100%] flex flex-col ">
                                <div className="justify-center flex">
                                    <img src={paymentImg} alt="پرداخت"
                                        className=" w-[350px] h-[350px]" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
            <div className="w-full">
                <Footer />
            </div>
        </div>
    )
}
export default PaymentSuccess;