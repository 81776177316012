import React from 'react'
import Header2 from '../header/Header2'
import Navbar from '../header/Navbar'
import { AnimatePresence, motion } from 'framer-motion'
import { useContext } from 'react'
import { StateApp } from '../context/Context'
import GetProfile from '../profile/GetProfile'
import { LuMenu } from 'react-icons/lu'
import { IoMdClose } from "react-icons/io";
import Footer from '../footer/Footer'

const RulesPage = () => {
  const { ShowNavbar, setShowNavbar } = useContext(StateApp);
  const handleNavbar = () => {
    setShowNavbar(!ShowNavbar);
  };
  return (
    <div className='h-screen w-screen'>

      {/* header */}
      <div className='md:hidden'>
        <Header2 />
      </div>
  
      {/* body  */}
      <div className='w-[90%] md:w-full md:p-6 mx-auto p-10'>
        <h2 className='font-bold text-[24px]'>قوانین و شرایط</h2>
        <div className='mt-10 flex flex-col gap-y-10'>
          <p>
            <span className='text-amber-500 font-bold'>نکته</span> : توجه داشته باشید در صورت رزرو بیش از یک روز قبل، با توجه به عدم امکان پیش بینی هوا – و جاده – در صورت بارندگی یا مسدود بودن جاده، کرایه 10 تا 20 درصد افزایش خواهد داشت. این مبلغ در روزها و ایامی که آب و هوای نا مناسب و یا پر ترافیک که زمان سفر طولانی تر است شامل افزایش قیمت خواهد بود که قبل از سفر با شرکت هماهنگ نمایید.
            در مسیر های جاده خاکی و کوهستانی یا صعب العبور شامل افزایش کرایه می باشد.
            در صورت مسدود بودن مسیر اصلی (کوتاه ترین مسیر تا مقصد) یا تغییر مسیر به درخواست و پیشنهاد مسافر و طولانی تر شدن مسیر یا برگشت اجباری حین مسیر (به دلایل مختلف) هزینه اضافه بر عهده مسافر می باشد.
            نکته : در صورت هوای گرم و درخواست مسافر، روشن نمودن کولر بدون هزینه باید صورت بگیرد.
            توجه بفرمایید در بعضی مسیر ها مثل شیب جاده، راننده جایز است کولر خودرو را خاموش نماید.(به علت آسیب ندیدن موتور خودرو)
          </p>

          <p>
            در صورت لغو سفر توسط راننده به دلایل شخصی یا نقص فنی خودرو و ...، مارانکو وظیفه دارد در اسرع وقت خودرو جایگزین در نظر بگیرد. در غیر این صورت مبلغ بیعانه (پیش پرداخت) به طور کامل عودت می گردد.
            در صورتی که با خودرو های دوگانه سوز سفر می نمایید و مایل نیستید راننده برای زدن گاز توقف نماید، با پرداخت 50000 تومن در ازای هر 200 کیلومتر راننده را مجاب بفرمایید برای زدن گاز توقف نداشته باشد.
            بسیار مهم : مسافر محترم توجه داشته باشید در صورتی که برای سفر برگشت یا سفر های بعدی با شخص راننده هماهنگ نمایید.
            شرکت هیچگونه مسئولیت و پشتیبانی در قبال سفر شما ندارد و هر پیش آمد و مسئولیتی بر عهده شخص مسافر و راننده خاطی می باشد.(با راننده خاطی برخورد خواهد شد)
            فقط سفر هایی با مسئولیت و پشتیبانی مارانکو می باشد که صرفا از شرکت یا اپلیکیشن مارانکو رزرو انجام شده باشد.

          </p>

          <p>
            سفر های رفت و برگشت :
            در صورتی که سفر رفت و برگشت شما در یک روز انجام شود، برگشت شما هزینه نصف محاسبه می گردد.
            هرگونه واگذاری سفر یا تغییر خودرو به هر دلیلی توسط راننده بدون هماهنگی شرکت غیر قابل قبول می باشد و در صورت بروز این اتفاق مسافر موظف است شرکت را سریعا مطلع کند، در غیر این صورت مسئولیت سفر به عهده مسافر و راننده می باشد.
            <br />هزینه توقف در مسیر به این صورت می باشد :
            <br />اقتصادی : 50.000 تومان
            <br />میان رده : 120.000 تومان
            <br /> معمولی : 80.000 تومان
            <br />  <span dir='ltr'> <span dir='rtl'> 180.000 تومان </span> : VIP </span>
            <br />  <span dir='ltr'> <span dir='rtl'> 220.000 تومان </span> : CIP </span>
            <br />  هزینه هر وعده غذا راننده و اقامتگاه راننده در سفر های چند روزه و در اختیار بر عهده مسافر می باشد و از کرایه کسر نخواهد شد.
          </p>

          <p>
            در زمان رزرو سفر بین 15 تا 20 درصد کل کرایه به عنوان پیش پرداخت دریافت می شود و الباقی کرایه به راننده پرداخت خواهد شد.
            در صورت کنسل نمودن سفر از جانب مسافر کل مبلغ پیش پرداخت به عنوان خسارت کسر خواهد شد و امکان برگشت ندارد.
            در صورت نیاز به فاکتور رسمی بعد از سفر با پشتیبانی تماس حاصل بفرمائید تا در واتسپ برای شما ارسال گردد. ارسال نسخه چاپی فاکتور نیز امکان پذیر می باشد.
            حداکثر مجاز تعداد مسافر در هر سفر3 نفر و یک کودک زیر 10 سال می باشد. اگر نفر چهارم بالای 10 سال باشد کرایه متعاقبا افزایش پیدا می کند.
            هر مسافر مجاز به همراه داشتن یک ساک دستی می باشد. در صورت اضافه بار، شامل هزینه اضافی می باشد.
            در صورت آسیب رساندن مسافر یا بار به خودرو مسافر موظف به پرداخت خسارت به راننده می باشد.
          </p>

          <p>
            مسافرین اتباع :
            در صورتی که مسافر از اتباع خارجی می باشد، حتما همراه داشتن مدارک (گذرنامه، برگ سند و ...) الزامی می باشد.
            در غیر این صورت راننده مجاز به انصراف از سفر و گرفتن خسارت از مسافر می باشد.

            در صورت همراه داشتن حیوان خانگی توجه بفرمایید حیوان شما باید داخل باکس حمل شود و در صورت کثیف نمودن خودرو هزینه کارواش بر عهده مسافر می باشد.
          </p>
        </div>

      </div>

      <div>
        <Footer/>
      </div>
    </div>
  )
}

export default RulesPage
