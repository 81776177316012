import React, { useContext, useEffect, useState } from "react";
import image from "../public/texture1.png";
import icon1 from "./public/svgexport-10.svg";
import icon2 from "./public/svgexport-11.svg";
import icon3 from "./public/svgexport-9.svg";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import LoginRegister from "../Login/LoginRegister";
import SuccessLogin from "../Modal/Success Login";
import { BiChevronDown } from "react-icons/bi";
import Submenu from "../profile/Submenu";
import { useSelector } from "react-redux";
import { StateApp } from "../context/Context";
import HeaderPicture from "./public/ra4 1.png";
import { Skeleton } from 'antd';
import logo from '../public/logo (2).png'

const Header2 = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subMenu, setSubmenu] = useState(false);

    const navigate = useNavigate()

    const showSubmenu = () => {
        setSubmenu(!subMenu);
    }
    const showModal = () => {
        setIsModalOpen(true);
        setOpenlogin(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleNavbar = () => {
        setShowNavbar(true)
    }

    const { userInfo, error } = useSelector((state) => state.userinformation);
    const { name, idCode, birthDate, gender, address, status, accessToken, mobile, loginInfo } = userInfo;
    const [token, setToken] = useState()

    useEffect(() => {
        setToken(accessToken);
    }, [accessToken]);

    const {
        ShowNavbar,
        setShowNavbar,
        openLogin,
        setOpenlogin,
        showSuccessLogin,
    } = useContext(StateApp);

    const [selectedImage, setSelectedImage] = useState(HeaderPicture);
    const [triggerAnimation, setTriggerAnimation] = useState(true);
    useEffect(() => {
        setTriggerAnimation(true)
    }, [selectedImage]);

    const handlenavigate = () => {
        navigate("/")
    }

    return (
        <>
            {openLogin && (
                <Modal
                    style={{ direction: "rtl", overflow: "hidden" }}
                    footer={null}
                    mask={true}
                    open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                >
                    <LoginRegister />
                </Modal>
            )}
            {showSuccessLogin && (
                <Modal
                    style={{ direction: "rtl", overflow: "hidden" }}
                    footer={null}
                    mask={true}
                    open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                >
                    <SuccessLogin />
                </Modal>
            )}
            <div className="h-[82px] items-center bg-cover" style={{ backgroundImage: `url(${image})` }}>
                <div className="">
                    {/*Nav menu */}
                    <div className="md:hidden grid grid-cols-2 tablet10:grid-cols-12 h-[82px] ">
                        <ul className="HeaderList  tablet10:!text-[14px] col-span-1 tablet10:col-span-5  items-center">
                            <li>
                                <div className="">
                                    <img src={logo} className="w-[65px] h-fit" />
                                </div>
                            </li>
                            <li className="cursor-pointer text-nowrap" onClick={handlenavigate} >
                                صفحه اصلی
                            </li>
                            {/* <li>
                                تماس با ما
                            </li> */}
                            <li className="cursor-pointer text-nowrap">
                                <a href="https://marankoo.ir/blog/">بلاگ</a>
                            </li>
                            <li className="cursor-pointer text-nowrap">
                                <a href="/rules">قوانین  </a>
                            </li>
                            <li className="cursor-pointer text-nowrap">
                                <a href="https://marankoo.ir/about-us/">درباره ما</a>
                            </li>
                        </ul>

                        <ul className=" tablet10:!text-[14px] select-none items-center tablet10:pl-[20px] pl-[20px] cursor-pointer HeaderList col-span-1 tablet10:col-span-7 justify-end  my-auto">
                            <li onClick={() => navigate("/support")} className="flex text-nowrap tablet10:gap-[5px] tablet10:ml-0 gap-[16px] ml-[32px]">
                                <img src={icon1} alt="icon1" className="HeaderIcons tablet10:!size-5" />
                                پشتیبانی
                            </li>
                            <li onClick={() => navigate("/passengersTrips")} className="flex text-nowrap tablet10:gap-[5px] tablet10:ml-0 gap-[16px] ml-[32px]">
                                <img src={icon2} alt="icon1" className="HeaderIcons tablet10:!size-5" />
                                سفرهای شما
                            </li>
                            <div>
                                {loginInfo ? (<div>
                                    {!token ? (
                                        <div>
                                            <li onClick={showModal} className="flex tablet10:gap-[5px] tablet10:ml-0 gap-[16px] ml-[32px] ">
                                                <img src={icon3} alt="icon1" className="HeaderIcons tablet10:!size-5" />
                                                <p className="text-nowrap"> ورود به حساب کاربری </p>
                                            </li>
                                        </div>
                                    ) : (
                                        <div className="relative">
                                            {/* passenger's username  */}
                                            <li onClick={showSubmenu} className="flex tablet10:gap-[5px] gap-[16px] ml-[10px] items-center">
                                                <img src={icon3} alt="icon1" className="HeaderIcons tablet10:!size-5" />
                                                <div className="flex gap-2 items-center">
                                                    <p className="text-[16px] text-center justify-self-center">{name ? name : mobile}</p>
                                                    <BiChevronDown
                                                        style={{ fontSize: "32px" }}
                                                        className={`transform transition-transform duration-300 ${subMenu ? 'rotate-180' : 'rotate-0'}`}
                                                    />
                                                </div>
                                            </li>
                                            {subMenu && (
                                                <>
                                                    <div className="absolute w-[220px] left-2">
                                                        <div className="slide-down translate-y-6 transform-gpu">
                                                            <Submenu />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}

                                </div>) : (
                                    <div>
                                        <Skeleton.Input
                                            active
                                            style={{ height: "30px", width: "auto" }}
                                        />
                                    </div>)}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Header2