import React, { useEffect, useState } from 'react'
import circleIcon from './public/Ellipse 47.svg'
import dotts from './public/Frame 36.svg'
import square from './public/Rectangle 3512.svg'
import detailArrow from './public/detailArrow.svg'
import { useNavigate } from 'react-router-dom'
import Header2 from '../header/Header2'
import { useContext } from 'react'
import { StateApp } from '../context/Context'
import Modal from "react-modal";
import axios from 'axios'
import { url } from '../url'
import Footer from '../footer/Footer'
import LoginRegister from '../Login/LoginRegister';
import { notification } from 'antd'
import { RxReload } from "react-icons/rx";
import jalaali from 'jalaali-js';
import { GiCarWheel } from "react-icons/gi";
import carMapic from '../TripPage/public/car-service-svgrepo-com.svg'
import SuccessLogin from '../Modal/Success Login';
import errorIcon from './public/errorIcon.svg'

const PassengersTrips = () => {
  const [allTrips, setAllTrips] = useState([]);
  const { loading, setLoading, openLogin, showSuccessLogin, updateUserName_header, setUpdateUserName_header } = useContext(StateApp);
  const { tripPrice, setTripPrice } = useContext(StateApp);
  const [apiError, setApiError] = useState(false);
  const [reloadAnimation, setReloadAnimation] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiRecall, setApiRecall] = useState(false);
  const [unavailableToken, setUnavailableToken] = useState(false);

  let navigate = useNavigate(null);

  const tryAgain = () => {
    setTimeout(() => {
      setApiRecall(prev => !prev)
      setReloadAnimation(false);
    }, 3000);
    setReloadAnimation(true);
  }

  useEffect(() => {
    setUnavailableToken(false);
    setApiError(false)
    axios.get(`${url}/travel/v1/get-all-travels-passenger`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(res => {
      setAllTrips(res.data.data.docs);
      console.log(res.data)
      setUnavailableToken(false);
      setApiError(false)
    }).catch(error => {
      console.log(error);
      if (error?.response?.status === 401)
        setUnavailableToken(true)
      if (error?.message === "Network Error")
        setApiError(true)
    })
  }, [apiRecall, showSuccessLogin]);

  const dateConvert = (item) => {
    const persianDate = jalaali.toJalaali(new Date(item.travel_date.slice(0, 10)).getFullYear(), new Date(item.travel_date.slice(0, 10)).getMonth() + 1, new Date(item.travel_date.slice(0, 10)).getDate())
    return `${persianDate.jy}/${persianDate.jm}/${persianDate.jd}`
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      borderRadius: '10px',
      outline: 'none',
      border: 'none'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }
  };


  return (
    <div className='h-screen flex flex-col justify-between'>
      <div className='md:hidden'>
        <Header2 />
      </div>
      {
        apiError ? (
          <div className='flex items-center justify-center py-10 px-5'>
            <div className='flex items-center gap-5 w-full flex-col'>
              <div className='errorSign bg-red-600 flex items-center justify-center'>
                <div className='errorSign2 bg-red-100 text-red-600 text-[84px] flex items-center justify-center'>!</div>
              </div>
              <p className='text-red-600 text-center'>خطا در برقراری ارتباط! <br className='md:block hidden'/> لطفا اینترنت خود را بررسی کنید...</p>
              <div className={` ${reloadAnimation ? "flex items-center gap-x-2" : "invisible"}`}>
                <p>منتظر بمانید...</p>
                <GiCarWheel className={`${reloadAnimation && "animate-spin text-[25px]"}`} />
              </div>
              <button className=' text-[18px] border-2 border-darkBlue text-darkBlue hover:bg-darkBlue hover:text-white rounded-lg py-2 px-4 mt-5' onClick={tryAgain}>تلاش مجدد</button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-y-3 justify-center items-center py-5 md:my-auto'>
            {
              <>
                {
                  allTrips.length > 0 ? (
                    <>
                      {
                        allTrips.map((item, index) => (
                          <div key={index} className='w-[90%] flex flex-col justify-center items-center border-2 border-blueBorder py-5 rounded-xl'>

                            <div className='flex flex-col border-b-2 border-slate-200 pb-3 w-[96%]'>
                              <div className='flex items-center justify-between w-full'>
                                <div className='flex items-center gap-x-2'>
                                  <div className='w-[10px] h-[10px] border-2 border-slate-300 rounded-full'></div>
                                  {item?.origin_city?.name}
                                </div>
                                <p className='text-greyFont'>{dateConvert(item)}</p>
                              </div>

                              <div className='mr-1'>
                                <img src={dotts} />
                              </div>

                              <div className='flex items-center gap-x-2'>
                                <div className='border-[2px] border-slate-300 rounded-sm w-[10px] h-[10px]'></div>
                                {item?.destination_city?.name}
                              </div>

                            </div>

                            <div className='mt-3 flex justify-between w-[96%]'>
                              <div className='flex gap-x-1'>
                                {item.price}
                                <p className='font-bold'>تومان</p>
                              </div>
                              <div onClick={() => navigate("/trip")} className='flex cursor-pointer gap-x-2'>
                                <p className='text-darkPink'>جزئیات</p>
                                <img width={10} src={detailArrow} />
                              </div>
                            </div>

                          </div>
                        ))
                      }
                    </>
                  ) :
                    (
                      <div className={`w-full h-full flex flex-col items-center justify-between text-[30px] py-[50px]`}>
                        <div className='flex gap-x-5 items-center md:flex-col md:mx-5'>
                          <img src={carMapic} />
                          <div className='flex flex-col md:mt-10'>
                            <p>{unavailableToken ? "شما وارد حساب کاربری نشده اید    !" : "شما سفر ثبت شده ای ندارید !"}</p>
                            <div className={` ${reloadAnimation ? "flex items-center gap-x-2 mt-5" : "hidden"}`}>
                              <GiCarWheel className={`${reloadAnimation && "animate-spin text-[25px]"}`} />
                              <p className='text-[20px]'>لطفا منتظر بمانید...</p>
                            </div>
                            <div className='flex items-center gap-x-2 md:justify-center md:flex-col md:items-stretch'>
                              <button className='bg-darkBlue w-full text-[18px] border-2 border-darkBlue rounded-lg py-2 px-4 text-white mt-5' onClick={() => unavailableToken ? setIsModalOpen(true) : tryAgain()}>{unavailableToken ? "ثبت نام" : "تلاش مجدد"}</button>
                              <button className='border-2 w-full border-darkBlue text-[18px] rounded-lg py-2 px-4 text-darkBlue mt-5 md:mt-2 text-nowrap' onClick={() => navigate("/")}>{unavailableToken ? "بازگشت به صفحه اصلی" : "ثبت سفر"}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                }
              </>

            }
          </div>
        )
      }
      <div>
        <Footer />
      </div>



      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        style={customStyles}
        className="modalMobile"
      >

        <div className=''>
          <button className="custom-modal-close" onClick={handleCancel}>
            &times;
          </button>
          {openLogin ? <LoginRegister /> : showSuccessLogin ? <SuccessLogin /> : null}
        </div>
      </Modal>
    </div >
  )
}

export default PassengersTrips
