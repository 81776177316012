import React, {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {StateApp} from "../context/Context";
import {setUser, setHandleError} from "../slices/UserInformation";
import userInformation from "../slices/UserInformation";
import {useRef} from "react";
import { url } from "../url";


const GetProfile = () => {
    const userInfo = useSelector((state) => state.userinformation);
    const dispatch = useDispatch();
    const token = localStorage.getItem('accessToken');
    const hasFetchedData = useRef(false);

    useEffect(() => {
        axios.get(`${url}/passenger/v1/get-my-profile`, {
            headers: {
                Authorization: `Bearer ${token && token}`
            }
        })
            .then((res) => {
                const data = res.data.data;
                dispatch(setUser({
                    name: data.full_name,
                    idCode: data.national_code,
                    birthDate: data.birth_date,
                    gender: data.gender,
                    address: data.address,
                    status: data.status,
                    mobile: data.mobile,
                    accessToken: token,
                    loginInfo: true,
                    loadingProfile:false,
                }));
            })
            .catch((error) => {
                dispatch(setHandleError(error.message));
                dispatch(setUser({
                    loginInfo: true
                }));
            });
    }, [token]);
};

export default GetProfile;