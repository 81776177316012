import { configureStore } from '@reduxjs/toolkit';
import LoginModal from "../slices/LoginModal";
import UserInformation from "../slices/UserInformation";
import TravelInformation from "../../src/slices/Travelinfo";

export default configureStore({
    reducer: {
        loginModal: LoginModal,
        userinformation: UserInformation,
        travelinformation: TravelInformation,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
