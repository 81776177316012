import {createSlice} from "@reduxjs/toolkit";
import Cookies from "js-cookie";



const initialState = {
    travelInfo: {
        travelId: Cookies.get("TravelId"),
        origin:'',
        destination:'',
        carType:'',
    },
};



const TravelInformation = createSlice({
    name: 'TravelInformation',
    initialState,
    reducers: {
        setTravelInfo: (state, action) => {
            state.travelInfo = action.payload;
            state.error = null;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});
export const {setTravelInfo: setTravel} = TravelInformation.actions


export default TravelInformation.reducer;