import React, { useState } from 'react'
import Header2 from '../header/Header2'
import Footer from '../footer/Footer'
import { MdOutlineNearbyError } from "react-icons/md"
import { IoReload } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

// ---- icons : 
import { BiSolidErrorAlt } from "react-icons/bi";

const AllErrors = () => {
  const [clickToAnimate, setClickToAnimate] = useState(false)
  let navigate = useNavigate()

  if (clickToAnimate) {
    setTimeout(() => {
      navigate("/trip");
    }, 3000);
  }
  return (
    <div className=' w-full h-[100vh]'>
      <div className='w-full md:hidden'>
        <Header2 />
      </div>

      <div className='text-[40px] md:text-[25px] h-[100vh] flex justify-center items-center flex-col'>
        <div className='flex items-center'>
          <p className='text-center'>سفر شما یافت نشد</p>
          <MdOutlineNearbyError />
        </div>
        <div
          onClick={() => setClickToAnimate(true)}
          className={`flex cursor-pointer items-center text-[24px]`}>
          <p className={`${clickToAnimate && "hidden"}`}>لطفا دوباره امتحان کنید</p>
          <IoReload className={`${clickToAnimate && "animate-spin text-[30px]"}`} />
        </div>
      </div>

      <div className='w-full '>
        <Footer />
      </div>
    </div>
  )
}

export default AllErrors
