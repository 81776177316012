import React, {useContext, useEffect, useState} from "react";
import {IoExitOutline} from "react-icons/io5";
import {LuUserCog} from "react-icons/lu";
import UpdateProfile from "../Login/UpdateProfile";
import SuccessLogin from "../Modal/Success Login";
import Modal from "react-modal";
import {useSelector} from "react-redux";
import GetProfile from "./GetProfile";
import { useNavigate } from "react-router-dom";
import { StateApp } from "../context/Context";

const Submenu = () => {
    const {userInfo, error} = useSelector((state) => state.userinformation);
    const {accessToken} = userInfo;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showManegeProfile, setShowManegeProfile] = useState(false);
    const {
        setshowSuccsessLogin,
    } = useContext(StateApp);
    const handleOk = () => {
        setShowManegeProfile(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let navigate = useNavigate(null)

    const showManageModal = () => {
        setShowManegeProfile(true);
        setIsModalOpen(true);
    }


    const logOut = () => {
        localStorage.removeItem('accessToken');
        // window.location.reload();
        setshowSuccsessLogin(false);
        navigate("/")
    }


    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            padding:'0px 0px 0px 0px',
            transform: 'translate(-50%, -50%)',
            position:'absolute',
            borderRadius:'10px',
            outline:'none',
            border:'none'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }
    };

    return (
        <div className="z-10">
            {showManegeProfile && (<div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={handleCancel}
                    style={customStyles}
                    className="modalMobile"
                >
                    <button className="custom-modal-close" onClick={handleCancel}>
                        &times;
                    </button>
                    <UpdateProfile/>
                </Modal>
            </div>)}
            <div className=" shadow-md p-4 text-black mt-3 bg-white w-auto h-auto rounded-xl flex flex-col gap-5">
                <div onClick={showManageModal} className="flex gap-2 items-center ">
                    <LuUserCog style={{fontSize: "22px"}}/>
                    <p>مدیریت حساب کاربری</p>
                    <GetProfile forceUpdate={{force: true}}/>
                </div>
                <div onClick={logOut} className="flex gap-2  items-center">
                    <IoExitOutline style={{fontSize: "22px", color: "red"}}/>
                    <p>خروج از حساب</p>
                </div>
            </div>
        </div>
    )
}

export default Submenu