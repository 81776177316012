import React from "react";


import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiFillInstagram } from "react-icons/ai";
import Logo from '../public/logo (2).png'
import { FaMobileScreenButton } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdOutgoingMail } from "react-icons/md";
import { TbWorldWww } from "react-icons/tb";
import logo1 from "../public/maranco2.png"


const Footer = () => {
    return (
        <div className="w-full h-[100%] bg-[#0C46B5]  py-[10px] md:py-[20px] flex flex-col">
            <img className="lg:mx-auto hidden lg:block w-[200px] mt-5" src={logo1} alt="Logo" />
            <div className="w-full h-full p-10 flex justify-between items-end flex-wrap gap-10">

                {/* توضیحات و لوگو */}

                <img className=" md:w-[65px] hidden tablet10:block tablet10:mb-5 w-[200px] lg:!hidden" src={logo1} alt="Logo" />

                <div className="flex flex-col w-[25%] tablet10:hidden md:hidden">
                    <img className=" md:w-[65px] w-[200px]" src={logo1} alt="Logo" />
                    <p className="text-white mt-5">
                        با اپلیکیشن مارانکو به راحتی، تاکسی دربست خود را رزرو کنید. ما با رانندگان مجرب و خودروهای متنوع، سفری راحت و ایمن را برای شما فراهم می‌کنیم. پشتیبانی ۲۴ ساعته، قیمت‌های مناسب و روش‌های پرداخت متنوع، از مزایای مارانکو هستند.
                    </p>
                </div>

                {/* راه های ارتباطی */}

                <div className="flex h-[122px] justify-between flex-col text-white ">
                    <p className="text-[20px]">راه های ارتباطی بیشتر</p>
                    <div className="flex flex-col mx-auto mt-5">
                        <div className="flex items-center gap-x-4"> <MdOutgoingMail /> <p>maranko@gmail.com</p></div>
                        <div className="flex items-center gap-x-4"> <FaPhone /> <p>09131547826</p></div>
                        <div className="flex items-center gap-x-4"> <TbWorldWww /> <p>www.maranko.com</p></div>
                    </div>
                </div>

                {/* دریافت اپلیکیشن */}

                <div className="flex h-[122px] justify-between flex-col text-white">
                    <p className="text-[20px]">اپلیکیشن</p>
                    <div className="flex flex-col gap-y-2 mt-5">
                        <a href="https://marankoo.ir/wp-content/uploads/android/maranko-passenger.apk" className="flex items-center gap-x-2 rounded-[10px]">دریافت مستقیم اپلیکیشن مسافر </a>
                        <a href="https://marankoo.ir/wp-content/uploads/android/maranko-driver.apk" className="flex items-center gap-x-2 rounded-[10px]">دریافت مستقیم اپلیکیشن راننده </a>
                    </div>
                </div>

                {/* فضاهای اجتماعی */}

                <div className="flex h-[122px] flex-col justify-between text-white ">
                    <p className="text-[20px] ">فضای اجتماعی</p>
                    <div className="flex text-[25px] items-center gap-x-5">
                        <BsTwitterX className="text-[18px] cursor-pointer" />
                        <FaTelegramPlane className="text-[20px] cursor-pointer" />
                        <TiSocialLinkedin className="cursor-pointer" />
                        <AiFillInstagram className="cursor-pointer" />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;



{/* <div className="flex flex-col text-white mx-auto order-3 xl:mt-14 xl:w-[90%]">
                            <p dir="rtl">راه های ارتباطی بیشتر :</p>
                            <div className="flex flex-col mx-auto mt-5 xl:flex-row xl:justify-between xl:w-full xl:flex-wrap xl:gap-2">
                                <div className="flex items-center gap-x-4"> <MdOutgoingMail /> <p>maranko@gmail.com</p></div>
                                <div className="flex items-center gap-x-4"> <FaPhone /> <p>09131547826</p></div>
                                <div className="flex items-center gap-x-4"> <TbWorldWww /> <p>www.maranko.com</p></div>
                            </div>
                        </div> */}

{/* <div className="xl:order-2 xl:w-full xl:mt-8 xl:flex xl:justify-end">
                            <div className=" flex flex-col" style={{ direction: "rtl" }}>
                                <p className="text-[16px] text-white my-2">دریافت اپلیکیشن</p>
                                <div className="flex flex-col xl:flex-row xl:w-full xl:items-center xl:justify-between xl:gap-x-5">
                                    <div className="bg-white flex items-center justify-between xl:w-full xl:h-[48px] p-2 rounded-xl">
                                        <FaMobileScreenButton className="flex-grow text-[24px]" />
                                        <input type="text" placeholder="شماره موبایل" className="w-full border-none outline-none text-left" />
                                    </div>
                                    <button className="mt-[10px] bg-[#4F48EC] xl:mt-0 w-[200px] h-[48px] flex-grow rounded-[10px]">
                                        <p style={{ color: "white", fontSize: "16px", fontWeight: "500" }}>ارسال لینک دانلود</p>
                                    </button>
                                </div>
                            </div>
                        </div> */}
{/* <div className="md:flex flex-col lg:!text-[200px] md:flex-col mx-auto flex justify-between items-end border-none lg:border-dashed border-b-2 lg:border-blue-600 lg:pb-5">
                            <div className=" w-full col-span-1 flex flex-col justify-center items-center mt-4">
                            </div>
                            <p className="max-w-[600px] w-full text-right text-white xl:max-w-[80%] md:max-w-[100%] xl:order-1">
                            </p>
                        </div> */}